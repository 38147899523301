<template>
  <v-card flat>
    <v-card-title class="justify-center align-center">
      <svg
        width="165"
        height="90"
        viewBox="0 0 145 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="dotnet-svg"
      >
        <g filter="url(#filter0_d)">
          <path
            d="M4 48.1718C4 47.0375 4.30336 46.0923 4.91008 45.3361C5.53847 44.5799 6.47023 44.2018 7.70534 44.2018C8.94045 44.2018 9.8722 44.5799 10.5006 45.3361C11.1507 46.0923 11.4757 47.0375 11.4757 48.1718C11.4757 49.2588 11.1507 50.1686 10.5006 50.9012C9.8722 51.6337 8.94045 52 7.70534 52C6.47023 52 5.53847 51.6337 4.91008 50.9012C4.30336 50.1686 4 49.2588 4 48.1718Z"
            fill="#783BD2"
          />
          <path
            d="M58.735 51.6101H52.4619L28.6372 11.8391V51.6101H22.3642V0H28.6372L52.5269 39.9482V0H58.735V51.6101Z"
            fill="#783BD2"
          />
          <path
            d="M96.5684 27.7546H76.0591V46.045H99.8837V51.6101H69.8185V0H99.5587V5.60055H76.0591V22.1895H96.5684V27.7546Z"
            fill="#783BD2"
          />
          <path
            d="M141 5.60055H125.789V51.6101H119.581V5.60055H104.402V0H141V5.60055Z"
            fill="#783BD2"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="145"
            height="60"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'DotNetAnimation',
};
</script>

<style scoped>
.dotnet-svg {
  /* width: 200px; */
  margin-top: 10px;
}
</style>
