<template>
  <v-container>
    <v-row>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-text class="text-left">
              {{rittalText}}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-col>
      <v-col class="col-xl-6 col-lg-5 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-title class="align-center justify-center">
              <v-img :src="rittal" contain max-width="310px" class="rittal" />
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RittalContent',
  data() {
    return {
      rittal: require('@/assets/capabilities/electrical_content/rittal.png'),
      rittalText: 'Rittal offers a perfectly coordinated system platform. It unites innovative productions, pioneering engineering solutions and global service to accommodate the most diverse requirements. It caters to a whole host of industries, from machinery and plant engineering, to the automotive industry, through to information technology',
    };
  },
};
</script>

<style scoped>
.rittal {
  margin-left: 25px;
  margin-top: 25px;
}

@media (max-width: 960px) {
  .row {
    flex-direction: column-reverse;
  }
}
</style>
