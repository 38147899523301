<template>
  <div>
    <v-dialog v-model="dialogue" max-width="400px" content-class="bottom-alligned-dialogue">
      <template v-slot:activator="{ on, attrs }">
        <v-card max-width="400px"
                max-height="400px"
                class="text-center justify-center"
                v-on="on"
                v-bind="attrs">
          <v-layout justify-center>
            <v-img :src="src" contain max-width="300px" max-height="300px"/>
          </v-layout>
          <v-card-title class="justify-center accent--text">{{ title }}</v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        </v-card>
      </template>

      <v-card class="text-center" height="auto" elevation="5">
        <v-card-title class="justify-center">
          <h2 class="pt-5">{{title}}</h2>
        </v-card-title>
        <v-spacer/>
        <v-card-subtitle class="text-center">
          <h3 class="black--text">{{subtitle}}</h3>
        </v-card-subtitle>
        <v-card-text>
          <p class="pa-5">{{ text }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
  name: 'CardSlide',
  props: ['title', 'subtitle', 'src', 'text'],
  data() {
    return {
      dialogue: false,
    };
  },
};
</script>

<style scoped>

 ::v-deep .bottom-alligned-dialogue {
    align-self: flex-end;
  }

  p {
    text-align: justify;
    /* text-justify: inter-word; */
  }

</style>
