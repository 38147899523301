<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col>
        <PlatformTargets/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="text-center">An Extensive prebuilt software suite for SCADA Management</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <h3 class="text-center">Smart Tools</h3>
        <v-card height="250px" elevation="0">
          <v-sparkline
              :value="value"
              :gradient="gradient"
              :smooth="radius || false"
              :padding="padding"
              :line-width="width"
              :stroke-linecap="lineCap"
              :gradient-direction="gradientDirection"
              :fill="false"
              :type="type"
              :auto-line-width="autoLineWidth"
              auto-draw
          ></v-sparkline>
          <p class="text-center break-text pt-5">
            <b>Utilize our Scada Portal equipped with modern data views</b>
          </p>
        </v-card>
      </v-col>
      <v-col class="pl-5 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <h3 class="text-center">Modern Technologies</h3>
        <v-carousel hide-delimiter-background hide-delimiters continuous cycle
                    class="pt-2"
                    :show-arrows="false"
                    :interval="5000"
                    height="250px"
                    :show-arrows-on-hover="false" >
          <v-carousel-item v-for="(item, i) in icons" :key="i">
            <v-card light elevation="0">
              <v-card-title class="justify-center">
                <v-icon x-large :color="item.color" >
                  mdi-{{item.icon}}
                </v-icon>
              </v-card-title>
              <v-card-text class="text-center pt-3">
                <v-chip :color="item.color" text-color="white">
                  {{item.name}}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <h3 class="text-center">Intelligent Process</h3>
        <v-card height="250px" elevation="0" class="pt-3">
          <v-card-title class="justify-center">
            <v-icon x-large :color="agileColour">
              mdi-{{ agileIcon }}
            </v-icon>
            <v-progress-linear :color="agileColour" reverse buffer-value="0" :indeterminate="agileRunning"/>
              <p class="text-center pt-5 break-text">
                <b>We use Agile for fast value delivery</b>
              </p>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlatformTargets from '@/Pages/services_content/PlatformTargets';

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
];

export default {
  name: 'SoftwareDevelopmentCapability',
  metaInfo: {
    title: 'Software Capabilities',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        description: 'ControlSoft offers software development capabilities to solve any your problems from data analytics & visualisation , process management, or integration with your exisiting systems. ',
      },
      {
        vmid: 'keyword',
        name: 'keyword',
        content: 'Software development, Software Engineering, dev, development, cloud services, cloud,',
      },
    ],
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {PlatformTargets},
  data: () => ({
    icons: [
      {
        icon: 'language-csharp',
        name: 'C# & .Net',
        color: '#783bd2',
      },
      {
        icon: 'vuejs',
        name: 'Vue.js',
        color: '#42b883',
      },
      {
        icon: 'language-javascript',
        name: 'JavaScript',
        color: '#f7df1e',
      },
      {
        icon: 'language-python',
        name: 'Python 3.x',
        color: '#4584b6',
      },
    ],
    agileIcon: 'human-male',
    agileColour: 'black',
    agileRunning: false,
    width: 3,
    radius: 10,
    padding: 8,
    lineCap: 'round',
    gradient: gradients[5],
    value: [0, 8, 1, 3, 11, 3, 11, 4, 3, 5, 1, 8, 2, 9, 0],
    gradientDirection: 'top',
    gradients,
    type: 'trend',
    autoLineWidth: false,
  }),
  methods: {
    animateAgile() {
      setTimeout(() => {
        this.agileIcon = 'walk';
        this.agileColour = 'red';
        setTimeout(() => {
          this.agileIcon = 'run';
          this.agileColour = 'orange';

          setTimeout(() => {
            this.agileIcon = 'run-fast';
            this.agileColour = 'green';
            this.agileRunning = true;
          }, 1500);
        }, 1500);
      }, 1500);
    },
  },
  mounted() {
    this.animateAgile();
  },
};
</script>

<style scoped>
  .break-text {
    word-break: normal;
  }
</style>
