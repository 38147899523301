<template>
  <div>
    <v-chip
        class="ma-2 padding-left-null"
        color="accent"
        label
        text-color="white">
      <v-icon left>mdi-account-group</v-icon>
      Graduate Automation Engineers
    </v-chip>
    <p>
      Graduate Automation Engineers have been co-funded in this company by the European Regional Development
      Fund and Enterprise Ireland under the Border, Midland and Western Regional Operational Programme
      2014-2020.
    </p>
    <h3>Aims</h3>
    <p>
      To recruit graduate Automation engineers to grow our workforce with motivated engineers with new
      skills and technologies fresh from the educational system.
    </p>

    <h3>Results</h3>
    <p>
      The Grad Start automation engineers has proven to be a fast learner in a role where latest
      technologies regularly meet with aging legacy systems. A rounded knowledge of both new and old is a
      successful combination in this position.
    </p>
  </div>
</template>

<script>
export default {
  name: 'GraduateAutomationEngineers',
};
</script>

<style scoped>
</style>
