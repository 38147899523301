<template>
    <div class="job-listing"
         @click="openListing()"
         @mouseover="play()"
         @mouseleave="pause()">
        <v-card outlined shaped class="job-listing-content">
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline">{{seniority}}</div>
                    <v-list-item-title>
                        <div class="Headline font-weight-bold accent--text">{{title}}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <p>{{description}}</p>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile class="rounded-bl-xl" size="75" color="accent">
                    <v-icon x-large color="white" :class="target">mdi-{{icon}}</v-icon>
                </v-list-item-avatar>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
import anime from 'animejs';
export default {
  name: 'JobListItem',
  props: ['seniority', 'title', 'description', 'icon', 'animation', 'id'],
  data() {
    return {
      playing: false,
      animationHandle: null,
      target: `icon-animation-${this.animation}-${this.id}`,
    };
  },
  methods: {
    play() {
      if (!this.playing) {
        this.animationHandle.play();
        this.playing = true;
      }
    },
    pause() {
      if (this.playing) {
        this.animationHandle.pause();
        this.playing = false;
      }
    },
    openListing() {
      const content = require('../../assets/jobs/' + this.id + '.md').default;
      this.$emit('display-job', content);
    },
  },
  mounted() {
    if (this.animation.toLowerCase() === 'spin') {
      this.animationHandle = anime({
        targets: '.' + this.target,
        duration: '1000',
        keyframes: [
          {rotate: 360},
        ],
        loop: true,
      });
    } else if (this.animation.toLowerCase() === 'wiggle') {
      this.animationHandle = anime({
        targets: '.' + this.target,
        duration: '500',
        delay: 500,
        keyframes: [
          {rotate: 5},
          {rotate: -10},
          {rotate: 5},
        ],
        loop: true,
      });
    }
    this.animationHandle.pause();
  },
};
</script>

<style scoped>
.job-listing-content {
  height: 150px;
}
.job-listing {
  cursor: pointer;
}
</style>
