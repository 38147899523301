<template>
  <IndustryContent banner-text="Bio Sciences - Client Case Study"
                   icon="flask-outline"
                   text="Bio-Sciences"
                   icon-color="lime">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <h2>Client</h2>
    <p>
      The client works in the medical industry, targeting specific hard to cure diseases. They are constantly working on
      producing solutions that go beyond in terms of treating illness’s .
    </p>
    <h2>Project Summary</h2>
    <p>
      The production of Electrical panels and software for automatic door releasing and opening system in a clean room
      environment.
    </p>
    <h2>Solution</h2>
    <p>
      Each clean room area consisted of interlocking doors which are accessible by way of card reader, wave sensor or
      break glass. The door locked by using a maglock and opened via an automated door handle or manually when the
      maglock released.
    </p>
    <p>
      The panel and code were designed in a generic fashion as to allow for the addition or removal of any door hardware
      dependent on the associated area and the automated or manual design of the doors.
      Each panel was fully commissioned, and IO tested before arrival onsite and further commissioning of fully
      installed system took place onsite.
    </p>
    <p>
      Each area consisted of a stand-alone panel with its own MicroLogix’s PLC and IO. These PLC’s were then networked
      via a ethernet topology to a master panel which consisted of CompactLogix’s PLC, HMI and BacNet. The HMI was
      designed to display all area’s and doors throughout the facility along with alarm and diagnostic capabilities.
      Each area consisted of a stand-alone panel with its own MicroLogix’s PLC and IO. These PLC’s were then networked
      via a ethernet topology to a master panel which consisted of CompactLogix’s PLC, HMI and BacNet. The HMI was
      designed to display all area’s and doors throughout the facility along with alarm and diagnostic capabilities.
    </p>
    <h2>Results</h2>
    <p>
      The system did not take long to commission due to the tireless effort made before visiting site. An experienced
      team onsite coupled with the very modular system Controlsoft employed resulted in a project that was both simple
      to commission and flexible enough to alter as required.
    </p>
    <p>
      The client was delighted with the commissioning which took place alongside active production. The operators were
      very pleased with the ease of use of the SCADA system and enjoyed having all their key parameters visible in a
      human-friendly way.
    </p>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';

export default {
  name: 'BioSciencesServed',
  components: {IndustryContent},
  data() {
    return {
      img: require('@/assets/industries_served/bioscience.jpg'),
    };
  },
};
</script>

<style scoped>

</style>
