<template>
  <div class="map-responsive">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2344.9966191288772!2d-6.381285783837378!3d54.00283558012186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860cc06fee1b07d%3A0xab652bce2bf37118!2sControlSoft%20Automation%20Systems!5e0!3m2!1sen!2sie!4v1599466382989!5m2!1sen!2sie"
        frameborder="0"
        style="border:0;"
        allowfullscreen="true"
        aria-hidden="false"
        tabindex="0">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
};
</script>

<style scoped>
.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
</style>
