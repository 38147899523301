<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'ControlSoft',
    titleTemplate: '%s | ControlSoft',
    meta: [
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:site_name',
        content: 'ControlSoft Automation Systems Limited Website',
      },
      {
        property: 'og:description',
        content: 'ControlSoft provides turnkey automation and software solutions to your problems',
      },
      {
        property: 'og:url',
        content: 'https://controlsoft.ie',
      },
    ],
  },
  created() {
    const ceil = Math.ceil;
    Object.defineProperty(Array.prototype, 'chunk', {
      value(n) {
        return Array(ceil(this.length / n)).fill().map((_, i) => this.slice(i * n, i * n + n));
      },
    });
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "BellGothicStd";
  src: local("BellGothicStd"),
  url(./assets/fonts/BellGothicStd-Black.otf) format("opentype");
}
* {
  word-break: normal !important;
}
</style>
