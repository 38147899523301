<template>
  <v-app >
    <TopNavigationMenu/>
    <v-main>
      <v-container fill-height fill-width fluid>
        <slot class="page-content"></slot>
      </v-container>
    </v-main>
    <PageFooter/>
  </v-app>
</template>

<script>
import PageFooter from './PageFooter';
import TopNavigationMenu from '@/templates/Page/TopNavigationMenu';

export default {
  name: 'Page',
  props: {
    name: String,
  },
  components: {
    TopNavigationMenu,
    PageFooter,
  },
};
</script>

<style>
p {
  font-size: medium;
}
h1, h2, h3, h4, h5, h6 {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-accent-base);
}
</style>
