<template>
  <IndustryContent v-if="this.page == 1" text="Food"
                   icon="food"
                   banner-text="Food Industry - Client Case Study"
                   icon-color="green">
    <v-layout class="page-selection" align-center justify-center>
      <v-btn elevation="2" icon large disabled>
        <p>1</p>
      </v-btn>
      <v-btn elevation="2" icon large @click="updatePage(2)">
        <p>2</p>
      </v-btn>
    </v-layout>
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax" />
    </v-layout>
    <h2>Client</h2>
    <p>
      Hilton is a dynamic & progressive business with a strong reputation for quality & innovation within the fresh food
      industry.
    </p>
    <p>
      Hilton Foods commenced operations in March 2004 in Ireland, with the opening of their Fresh Meat production
      Facility. In 2007 Hilton expanded their operations opening a second factory which supplies Cured meats such as
      bacon joints, hams, and their own label sausages.
    </p>
    <h2>Project Summary</h2>
    <p>
      The development of a full Scada and reporting system for the meat production and storage company.
    </p>
    <h2>Solution</h2>
    <p>
      The SCADA application provides graphic screens for monitoring system variables and system operation, changing
      system control and setpoint parameters, alarm summary and alarm history screens, and trends. The SCADA application
      uses the same tag structure or PLC data via the ControlLogix OPC database which in turn talks to the Siemens PLC
      via a Kepware OPC server
    </p>
    <p>
      The purpose of the SCADA system is the continuous supervision of the equipment utilized within the production and
      storage system. The SCADA system consist of:
    </p>
    <ul>
      <li>An overall layout of the system.</li>
      <li>links to detailed graphics of each individual area.</li>
      <li>
        The state of each PLC device:
        <ul>
          <li>Motor</li>
          <li>Sensor</li>
          <li>Switch</li>
        </ul>
      </li>
    </ul>
    <p>
      This SCADA information is made easily accessible using visual displays aiding the diagnosis of any errors
      Supporting Software:
    </p>
    <ul>
      <li>Rockwell FactoryTalk View Studio</li>
      <li>FactoryTalk View Site Edition (SE)</li>
      <li>Windows Server 2012R2</li>
      <li>ControlLogix OPC database</li>
      <li>Kepware OPC server</li>
      <li>Real-time Operational Support Dashboards</li>
    </ul>
    <h2>Results</h2>
    <p>
      The result is a fully functional system wide Scada and reporting system to help facilitate in the growth of
      production by pinpointing the Availability, Performance and Quality of all areas and allowing quick action to be
      taken to keep production at a high-level.
    </p>
  </IndustryContent>
  <IndustryContent v-else text="Food"
                   icon="food"
                   banner-text="Food Industry - Client Case Study"
                   icon-color="green">
    <v-layout class="page-selection" align-center justify-center>
      <v-btn elevation="2" icon large @click="updatePage(1)">
        <p>1</p>
      </v-btn>
      <v-btn elevation="2" icon large disabled>
        <p>2</p>
      </v-btn>
    </v-layout>
    <h2>Case Study</h2>
    <p>
      Portumna Pastry Ltd- SIEMENS SINAMICS MOTION MACHINE UPGRADE
    </p>
    <h2>Original Installation</h2>
    <p>1998</p>
    <h2>Job Title</h2>
    <p>SIEMENS SINAMICS MOTION MACHINE UPGRADE- Portumna Pastry LTD</p>
    <h2>Introduction</h2>
    <p>
      For more than 30 years, Portumna Pastry Ltd has offered a large range of frozen unbaked Pastry products
      in the Savoury, Meat Free, Vegan and Sweet categories for their retail and foodservice customers.
    </p>
    <br />
    <h1>Project Background</h1>
    <h2>Design Consultation</h2>
    <p>
      The production line was down due to a failure with obsolete components. The machines mechanical system was
      in good working order, but the electrical control system had reached the end of its service life.
      This destruction has resulted in a total breakdown of the equipment, ceasing all production activities and
      requiring an urgent assessment for either retrofitting with compatible modern components or replacement with a new machine
      to resume operations.
    </p>
    <p>
      The breakdown of the packaging machine at Portumna Pastry Ltd was critical to production because it
      halted the final stage of the manufacturing process, where goods are packaged for distribution.
      Without this machine, the entire production line was disrupted, preventing the timely packaging and delivery of products.
    </p>
    <p>
      ControlSoft produced an obsolescence report and designed a new electrical control system to bring the machine back to life.
      ControlSoft recommended best in class automation components that were in line with site standards and supported globally.
    </p>
    <h2>Objectives and Scope</h2>
    <p>
      The primary objectives of the project are to restore the packaging machine to optimal operational status,
      ensuring it runs smoothly without any errors, and to reinstate full production capacity as swiftly as possible.
      This entails a thorough assessment and replacement of the obsolete components with compatible parts or new technology,
      rigorous testing to confirm error-free functionality, and immediate implementation to minimize downtime.
      The focus is on achieving seamless machine performance and rapidly resuming full-scale production to meet demand
      and maintain the high standards of Portumna Pastry Ltd's operations.
    </p>
    <h2>Preliminary Design, Quote and Simulation</h2>
    <p>
      The solution to address the critical breakdown of the packaging machine involved a comprehensive overhaul of its core components.
      ControlSoft's new design included replacing the complete electrical control system, electrical panel upgrade and replacing motors
      and gear boxes. The selected control system key components were SIEMENS S71515 PLC, SIEMENS SINAMICS S110 SERVO DRIVES,
      SIEMENS SINAMICS G120 VSD's and SIEMENS TP1200 HMI's to ensure best in class automation performance and reliability.
      A new electrical panel was designed and built in our factory and installed on-site. The machine underwent reprogramming to
      integrate the new components seamlessly, A SIEMENS TP1200 HMI was introduced to enhance user interface and simplify the control.
    </p>
    <p>
      Additionally, Field wiring and devices on the machine were upgraded on-site to ensure proper alignment and functionality.
      This extensive refurbishment aimed to restore the machine to full operational status, ensuring efficient and error-free production.
    </p>
    <h2>Detailed Design and Build</h2>
    <p>
      The implementation of the solution for the packaging machine breakdown at Portumna Pastry Ltd was planned and executed in several
      phases. First, during the design phase, a collaborative team of mechanical, electrical, and automation engineers gathered detailed
      information and formulated a comprehensive solution. This phase involved thorough analysis and design to address all aspects of
      the machine's operation. Next, Electrical panels were designed and built in our factory, and PLC programs were redesigned and new
      interactive HMI screens were produced, ensuring smooth integration with the updated system. Following this, EPLAN designed electrical
      control panels were installed and integrated onsite. Finally, the Commissioning phase involved rigorous testing, calibration and
      ensuring the line was returned to full capacity in a minimum duration. To verify that the machine operated smoothly and without
      errors, successfully restoring full production capabilities.
    </p>
    <h3>Factory Acceptance Test, Installation Support, Site Acceptance Test, Validation, Service Level Agreement</h3>
    <!-- <h2>Factory Acceptance Test</h2>
    <h2>Installation Support</h2>
    <h2>Site Acceptance Test</h2>
    <h2>Validation</h2>
    <h2>Service Level Agreement</h2> -->
    <p>
      The results of the project were highly successful, with the packaging machine now operating at full capacity once again.
      The integration of new components has introduced additional functions, enhancing the machine's overall capabilities.
      It runs smoothly without errors, ensuring consistent and efficient production. The redevelopment of the HMI has made the
      machine more user-friendly and easier to navigate, improving the operator experience. Furthermore, the upgraded system
      includes advanced diagnostics, allowing for quicker identification and resolution of issues, thereby minimizing downtime
      and ensuring sustained productivity at Portumna Pastry Ltd.
    </p>
    <h2>Customer Feedback</h2>
    <p>
      The customer expressed high satisfaction with the project's execution and outcomes. Engineering Manager at Portumna Pastry Ltd
      praised Controlsoft's exceptional service, noting, "No matter what time I phoned, somebody from Controlsoft would always pick up."
      He appreciated the transparency throughout the process, stating, "The communication was always honest and I knew where I stood."
      Furthermore, he commended the onsite performance, remarking, "Controlsoft engineers were excellent when they came on site,
      wasting no time." These testimonials highlight the dedication and professionalism that ensured the project's success and
      reinforced a positive client relationship.
    </p>
    <h2>Images Before</h2>
    <v-img :src="img_food2[0]" class="parallax" /><br />
    <v-img :src="img_food2[1]" class="parallax" /><br />
    <v-img :src="img_food2[2]" class="parallax" /><br />
    <v-img :src="img_food2[3]" class="parallax" /><br />
    <v-img :src="img_food2[4]" class="parallax" /><br />
    <br />
    <br />
    <h2>Images After</h2>
    <v-img :src="img_food2[5]" class="parallax" /><br />
    <v-img :src="img_food2[6]" class="parallax" /><br />
    <v-img :src="img_food2[7]" class="parallax" /><br />
    <v-img :src="img_food2[8]" class="parallax" /><br />
    <v-img :src="img_food2[9]" class="parallax" /><br />
    <br />
    <br />
    <h2>References</h2>
    <p>
      The Portumna Pastry Ltd project was a success, aided by Almeric Underwood, a trusted Siemens distributor. The use of Siemens
      components in this project were key to modernising the production line, enhancing efficiency and quality. ControlSoft Automation
      Systems understanding of Siemens technology ensured Portumna Pastry Ltd received the most suitable components, facilitating a
      seamless upgrade.
    </p>
    <v-img :src="img_food2[10]" class="parallax" />
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';
import eventBus from '@/eventBus';

export default {
  name: 'FoodServed',
  components: {IndustryContent},
  mounted() {
    const lastChar = this.$route.path.charAt(this.$route.path.length - 1);
    if (lastChar == '2') {
      this.page = parseInt(lastChar, typeof(lastChar));
    }
  },
  data() {
    return {
      img: require('@/assets/industries_served/food.jpg'),
      img_food2: [
        require('@/assets/industries_served/food2/image_01.jpg'),
        require('@/assets/industries_served/food2/image_02.jpg'),
        require('@/assets/industries_served/food2/image_03.jpg'),
        require('@/assets/industries_served/food2/image_04.jpg'),
        require('@/assets/industries_served/food2/image_05.jpg'),
        require('@/assets/industries_served/food2/image_06.jpg'),
        require('@/assets/industries_served/food2/image_07.jpg'),
        require('@/assets/industries_served/food2/image_08.jpg'),
        require('@/assets/industries_served/food2/image_09.jpg'),
        require('@/assets/industries_served/food2/image_10.jpg'),
        require('@/assets/industries_served/food2/image_11.jpg'),
      ],
      page: 1,
    };
  },
  methods: {
    updatePage(page) {
      this.page = page;
      if (page == 1) {
        eventBus.$emit('update-url', 'Food');
      } else {
        eventBus.$emit('update-url', `Food${page}`);
      }
    },
  },
};
</script>

<style scoped>
  .page-selection .v-btn {
    margin-left: 4px;
    margin-right: 4px;
  }
  .page-selection p {
    margin-top: 10px;
    font-size: 30px;
  }

</style>
