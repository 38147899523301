<template>
  <div>
    <v-chip
        class="ma-2 padding-left-null"
        color="accent"
        label

        text-color="white">
      <v-icon left>mdi-account-plus</v-icon>
      Chief Financial Officer
    </v-chip>
    <p>
      A Key Manager has been co-funded in this company by the European Regional Development Fund and
      Enterprise Ireland under the Border, Midland and Western Regional Operational Programme 2014-2020.
    </p>

    <h3>Aims</h3>
    <p>
      To recruit a Key Manager who will bring skills into the company that are critical to future growth.
    </p>

    <h3>Results</h3>
    <p>
      The Key Manager in the role of Chief Financial Officer (CFO) has commenced employment.
      The CFO has now put in place strong controls and processes around the finances of the company.
      Up-to-date accurate financial information relating the company’s performance is now readily available
      to aid in decision making. The CFO’s input will continue to assist management on growing the business
      in a sustainable and profitable way.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ChiefFinancialOfficer',
};
</script>

<style scoped>

</style>
