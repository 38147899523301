import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/Pages/Home';
import About from '@/Pages/About';
import Contact from '@/Pages/Contact';
import Services from '@/Pages/Services';
import Funding from '@/Pages/Funding';
import NotFound from '@/Pages/NotFound';
import Careers from '@/Pages/Careers';
import PrivacyPolicy from '@/Pages/PrivacyPolicy';
import EULA from '@/Pages/EULA';
import Industry from '@/Pages/Industry';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/funding',
    name: 'Funding',
    component: Funding,
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/privacy',
    name: 'Privacy policy',
    component: PrivacyPolicy,
  },
  {
    path: '/eula',
    name: 'End User License Agreement',
    component: EULA,
  },
  {
    path: '/industry/:id?',
    name: 'industry sharing',
    component: Industry,
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
