<template>
    <v-card class="career-posting">
        <v-card-text>
            <div v-html="posting"/>
        </v-card-text>
    </v-card>
</template>

<script>
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

export default {
  name: 'CareerPosting',
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },
  computed: {
    posting() {
      return md.render(this.markdown);
    },
  },
};
</script>

<style>
.career-posting h1  {
    padding-top: 10px;
    padding-bottom: 10px;
}
.career-posting h2  {
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>
