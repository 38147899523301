<template>
  <v-container>
    <v-row justify="center" align="center">
      <ElectricalPartnersContainer/>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="text-center">{{electricalHeading}}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
      <v-card-text class="text-center">
        {{electricalOverview}}
      </v-card-text>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="tab" center-active background-color="accent" grow>
          <v-tab v-for="item in items" :key="item.name">
            {{item.name}}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
       <v-tab-item v-for="item in items" :key="item.name">
        <v-timeline dense>
          <v-timeline-item v-for="panel in item.contentPanels" :key="panel.title" :color="!panel.collapsed ? 'accent' : 'primary'" fill-dot>
            <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ?  panel.collapsed ? 20 : 2 : 2"
              :class="{ 'on-hover': hover }">
              <v-card-title v-on:click="collapse(panel, tab)"
              :class="panel.collapsible ? panel.collapsed? !panel.collapsed? 'timeline-active' : 'timeline' : 'accent' : 'accent'">
                <v-icon dark size="42" class="mr-4">
                  {{panel.icon}}
                </v-icon>
                <h2 class="display-1 white--text font-weight-light">{{panel.title}}</h2>
              </v-card-title>
              <v-expand-transition>
              <v-container class="collapsible" v-show="!panel.collapsed" style="text-align: center;">
                <v-row justify="center" align="center">
                  <v-col cols="12" md="10">
                    <span v-html="panel.text"></span>
                  </v-col>
                </v-row>
                <div v-if="panel.text != null">
                  <v-divider></v-divider>
                </div>
                <div v-if="panel.image != null">
                  <img v-img="{group: item.name}" :src=panel.image class="visual-components" />
                </div>
              </v-container>
              </v-expand-transition>
            </v-card>
            </v-hover>
          </v-timeline-item>
        </v-timeline>
       </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ElectricalPartnersContainer from '@/Pages/services_content/capabilities/ElectricalPartnersContainer';

export default {
  name: 'ElectricalCapability',
  components: {ElectricalPartnersContainer},
  metaInfo: {
    title: 'Electrical Capabilities',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        description: 'ControlSoft offers Electrical and Panel building services from design to completeion',
      },
      {
        vmid: 'keyword',
        name: 'keyword',
        content: 'Electrical, Panel, Panel Building, Panel Design, Electrical schematics',
      },
    ],
    htmlAtts: {
      lang: 'en',
    },
  },
  data() {
    return {
      items: [
        {
          name: 'Sample Project',
          contentPanels: [
            {
              icon: 'mdi-numeric-1-box-multiple',
              title: 'Design Consultation',
              image: '',
              text: 'ControlSoft will help you figure out what you need, and gather requirements as a part of the consultation process.',
              collapsed: false,
              collapsible: false,
            },
            {
              icon: 'mdi-numeric-2-box-multiple',
              title: 'Design & Quotation',
              image: require('@/assets/capabilities/electrical_content/PanelLayout.png'),
              text: 'ControlSoft will create a 3-D concept of the design for you to review.',
              collapsed: true,
              collapsible: true,
            },
            {
              icon: 'mdi-numeric-3-box-multiple',
              title: 'Design (Front View)',
              image: require('@/assets/capabilities/electrical_content/designFront.png'),
              text: null,
              collapsed: true,
              collapsible: true,
            },
            {
              icon: 'mdi-numeric-4-box-multiple',
              title: 'Design (Inside View)',
              image: require('@/assets/capabilities/electrical_content/designInside.png'),
              text: null,
              collapsed: true,
              collapsible: true,
            },
            {
              icon: 'mdi-numeric-5-box-multiple',
              title: 'Build and test (outside)',
              image: require('@/assets/capabilities/electrical_content/frontView.png'),
              text: null,
              collapsed: true,
              collapsible: true,
            },
            {
              icon: 'mdi-numeric-6-box-multiple',
              title: 'Build and test (inside)',
              image: require('@/assets/capabilities/electrical_content/inside.jpg'),
              text: null,
              collapsed: false,
              collapsible: false,
            },
            {
              icon: 'mdi-numeric-7-box-multiple',
              title: 'Completion',
              text: 'The build is complete. The panel can be shipped world wide. ControlSoft also offers installation support options.',
              collapsed: false,
              collapsible: false,
            },
            {
              icon: 'mdi-numeric-8-box-multiple',
              title: 'On Site',
              image: require('@/assets/capabilities/electrical_content/eplanSchematics.png'),
              text: '<ul style="text-align: left;"><li>Changes made on site - no problem</li><li>Redline and comment on schematics via browser based tools</li><li>ControlSoft then reissue as built drawings</li></ul>',
              collapsed: true,
              collapsible: true,
            },
          ],
        },
        // Add new project tabs here
      ],
      electricalHeading: 'ControlSoft offers high quality panel building service from design to completion',
      electricalOverview: 'Our design process using Eplan along with Rittal allows us to be efficient and cost effective. From your initial contact with ControlSoft regarding a panel we use Eplan as a tool to help with the project. From an accurate quote to 3D design right through to service and maintenance Eplan helps us to achieve efficient engineering. ',
      eplanImage: require('@/assets/capabilities/electrical_content/eplan.png'),
      rittalImage: require('@/assets/capabilities/electrical_content/rittal.png'),
      tab: null,
      expanded: false,
    };
  },
  methods: {
    collapse(panel, tabIndex) {
      this.collapseAll(tabIndex);
      panel.collapsed = false;
      // if tab content stops updating correctly, enable these
      // this.tab = null;
      // this.tab = tabIndex;
    },
    collapseAll(tabIndex) {
      for (let i = 0; i < this.items[tabIndex].contentPanels.length; i++) {
        if (this.items[tabIndex].contentPanels[i].collapsible == true) {
          this.items[tabIndex].contentPanels[i].collapsed = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.visual-components {
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: 100%;
  max-height: 600px;
}

.timeline {
  background-color: var(--v-primary-base);
  cursor: auto;
}

.timeline:hover {
  background-color: var(--v-accent-base);
  cursor: auto;
}

.timeline-active {
  background-color: var(--v-primary-base);
  cursor: auto;
}

.timeline-active:hover {
  background-color: var(--v-accent-base);
  cursor: pointer;
}

</style>
