<template>
  <v-card flat id='vmodel' class='fade'>
    <v-card-text class='vmodel-card'>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 -80 540 500"
        width="100%"
        height="100%"
      >
        <defs>
          <path
            d="M309.1 306.29L308.33 305.66L315.85 296.88L323.33 305.66L322.57 306.29L316.33 298.94L316.33 320.31L315.33 320.31L315.33 298.94L309.1 306.29Z"
            id="b19DRFVM6d"
          ></path>
          <text
            id="g4s4wLBP95"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 212.84706512451166 310.21875000000006)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              System Build/
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Configuration
            </tspan>
          </text>
          <path
            d="M210.76 310.89L210 311.52L217.52 320.31L225 311.52L224.24 310.89L218 318.25L218 296.88L217 296.88L217 318.25L210.76 310.89Z"
            id="b5IJDvMEK"
          ></path>
          <path
            d="M342.43 228.17L341.67 227.54L349.19 218.75L356.67 227.54L355.9 228.17L349.67 220.82L349.67 242.19L348.67 242.19L348.67 220.82L342.43 228.17Z"
            id="apK0NE1Lw"
          ></path>
          <path
            d="M177.43 232.77L176.67 233.4L184.19 242.19L191.67 233.4L190.9 232.77L184.67 240.12L184.67 218.75L183.67 218.75L183.67 240.12L177.43 232.77Z"
            id="a7cdg6XJ8F"
          ></path>
          <path
            d="M376.6 150.04L375.83 149.41L383.35 140.63L390.83 149.41L390.07 150.04L383.83 142.69L383.83 164.06L382.83 164.06L382.83 142.69L376.6 150.04Z"
            id="c12itXJmDH"
          ></path>
          <path
            d="M409.1 71.92L408.33 71.29L415.85 62.5L423.33 71.29L422.57 71.92L416.33 64.57L416.33 85.94L415.33 85.94L415.33 64.57L409.1 71.92Z"
            id="a10ZqzLOhy"
          ></path>
          <path
            d="M110.76 76.52L110 77.15L117.52 85.94L125 77.15L124.24 76.52L118 83.87L118 62.5L117 62.5L117 83.87L110.76 76.52Z"
            id="eb07NDsY2"
          ></path>
          <path
            d="M256.67 120.81C256.67 126.33 261.15 130.81 266.67 130.81C272.18 130.81 276.67 126.33 276.67 120.81C276.67 115.3 272.18 110.81 266.67 110.81C261.15 110.81 256.67 115.3 256.67 120.81ZM278.67 120.81C278.67 127.44 273.29 132.81 266.67 132.81C260.04 132.81 254.67 127.44 254.67 120.81C254.67 114.19 260.04 108.81 266.67 108.81C273.29 108.81 278.67 114.19 278.67 120.81ZM269.17 125.31L267.64 126.81L261.67 120.81L267.64 114.81L269.17 116.31L264.67 120.81L269.17 125.31Z"
            id="circle-three"
            class="circle-three"
          ></path>
          <path
            d="M256.67 42.69C256.67 48.2 261.15 52.69 266.67 52.69C272.18 52.69 276.67 48.2 276.67 42.69C276.67 37.17 272.18 32.69 266.67 32.69C261.15 32.69 256.67 37.17 256.67 42.69ZM278.67 42.69C278.67 49.31 273.29 54.69 266.67 54.69C260.04 54.69 254.67 49.31 254.67 42.69C254.67 36.06 260.04 30.69 266.67 30.69C273.29 30.69 278.67 36.06 278.67 42.69ZM269.17 47.19L267.64 48.69L261.67 42.69L267.64 36.69L269.17 38.19L264.67 42.69L269.17 47.19Z"
            id="circle-four"
            class="circle-four"
          ></path>
          <text
            id="a2qHqMBygG"
            x="250"
            y="253.5"
            font-size="13"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 -4.974346393504657 -159.75)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="250"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Verifies
            </tspan>
          </text>
          <text
            id="a61rZmEEq"
            x="250"
            y="253.5"
            font-size="13"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 -4.974346393504582 -239.91304347826087)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="250"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Verifies
            </tspan>
          </text>
          <text
            id="b7mgtuHBLO"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 13.029479248046862 3.21875)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Requirements
            </tspan>
          </text>
          <path
            d="M144.1 154.64L143.33 155.27L150.85 164.06L158.33 155.27L157.57 154.64L151.33 162L151.33 140.63L150.33 140.63L150.33 162L144.1 154.64Z"
            id="b1odxFXxYf"
          ></path>
          <text
            id="c2RLzacuNp"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 45.69439193725581 75.51041666666666)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
                Functional
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Specifications
            </tspan>
          </text>
          <text
            id="a1JE2Jj3Rj"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 384.6525169372555 74.77430555555554)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
               Functional
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Testing(OQ)
            </tspan>
          </text>
          <text
            id="an5BoormY"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 413.0292292480464 -3.229167090521912)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Requirements
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
                Testing(PQ)
            </tspan>
          </text>
          <path
            d="M517.19 16.94C521.5 16.94 525 20.44 525 24.75C525 30.74 525 40.89 525 46.87C525 51.19 521.5 54.69 517.19 54.69C495.42 54.69 437.92 54.69 416.15 54.69C411.83 54.69 408.33 51.19 408.33 46.87C408.33 40.89 408.33 30.74 408.33 24.75C408.33 20.44 411.83 16.94 416.15 16.94C437.92 16.94 495.42 16.94 517.19 16.94Z"
            id="aoQVoFOq9"
          ></path>
          <path
            d="M483.85 93.75C488.17 93.75 491.67 97.25 491.67 101.56C491.67 107.55 491.67 117.7 491.67 123.68C491.67 128 488.17 131.5 483.85 131.5C462.08 131.5 404.58 131.5 382.81 131.5C378.5 131.5 375 128 375 123.68C375 117.7 375 107.55 375 101.56C375 97.25 378.5 93.75 382.81 93.75C404.58 93.75 462.08 93.75 483.85 93.75Z"
            id="h3nAkGu7LP"
          ></path>
          <text
            id="b6v83mNlF"
            x="250"
            y="253.5"
            font-size="13"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 -4.9743463935046 -3.0592900302115495)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="250"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Verifies
            </tspan>
          </text>
          <path
            d="M256.67 277.06C256.67 282.58 261.15 287.06 266.67 287.06C272.18 287.06 276.67 282.58 276.67 277.06C276.67 271.55 272.18 267.06 266.67 267.06C261.15 267.06 256.67 271.55 256.67 277.06ZM278.67 277.06C278.67 283.69 273.29 289.06 266.67 289.06C260.04 289.06 254.67 283.69 254.67 277.06C254.67 270.44 260.04 265.06 266.67 265.06C273.29 265.06 278.67 270.44 278.67 277.06ZM269.17 281.56L267.64 283.06L261.67 277.06L267.64 271.06L269.17 272.56L264.67 277.06L269.17 281.56Z"
            id="circle-one"
            class="circle-one"
          ></path>
          <text
            id="anbRDOwXu"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 308.3331406249999 231.59722222222223)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Unit/Integration
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
                     Testing
            </tspan>
          </text>
          <text
            id="a18JUikmgT"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 112.36101693725585 231.76041666666669)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
                    Design
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Specifications
            </tspan>
          </text>
          <path
            d="M417.19 250C421.5 250 425 253.5 425 257.81C425 263.8 425 273.95 425 279.93C425 284.25 421.5 287.75 417.19 287.75C395.42 287.75 337.92 287.75 316.15 287.75C311.83 287.75 308.33 284.25 308.33 279.93C308.33 273.95 308.33 263.8 308.33 257.81C308.33 253.5 311.83 250 316.15 250C337.92 250 395.42 250 417.19 250Z"
            id="bKJWbjNQQ"
          ></path>
          <path
            d="M217.19 251.32C221.5 251.32 225 254.81 225 259.13C225 265.12 225 275.26 225 281.25C225 285.56 221.5 289.06 217.19 289.06C195.42 289.06 137.92 289.06 116.15 289.06C111.83 289.06 108.33 285.56 108.33 281.25C108.33 275.26 108.33 265.12 108.33 259.13C108.33 254.81 111.83 251.32 116.15 251.32C137.92 251.32 195.42 251.32 217.19 251.32Z"
            id="d4Di1sIbX"
          ></path>
          <text
            id="b5iHdYDTL"
            x="250"
            y="253.5"
            font-size="13"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 -4.974346393504565 -81.62500000000004)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="250"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
              Verifies
            </tspan>
          </text>
          <path
            d="M256.67 198.94C256.67 204.45 261.15 208.94 266.67 208.94C272.18 208.94 276.67 204.45 276.67 198.94C276.67 193.42 272.18 188.94 266.67 188.94C261.15 188.94 256.67 193.42 256.67 198.94ZM278.67 198.94C278.67 205.56 273.29 210.94 266.67 210.94C260.04 210.94 254.67 205.56 254.67 198.94C254.67 192.31 260.04 186.94 266.67 186.94C273.29 186.94 278.67 192.31 278.67 198.94ZM269.17 203.44L267.64 204.94L261.67 198.94L267.64 192.94L269.17 194.44L264.67 198.94L269.17 203.44Z"
            id="circle-two"
            class="circle-two"
          ></path>
          <text
            id="a2i5LkSNX"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 79.0277044372558 154.19097222222226)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Configuration
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Specifications
            </tspan>
          </text>
          <text
            id="bangCP6nl"
            x="16.67"
            y="23.44"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 347.404272186279 153.0798611111111)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="16.67"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Configuration
            </tspan>
            <tspan
              x="16.67"
              dy="1.2em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
                 Testing(IQ)
            </tspan>
          </text>
          <path
            d="M450.52 171.88C454.84 171.88 458.33 175.37 458.33 179.69C458.33 185.67 458.33 195.82 458.33 201.81C458.33 206.12 454.84 209.62 450.52 209.62C428.75 209.62 371.25 209.62 349.48 209.62C345.16 209.62 341.67 206.12 341.67 201.81C341.67 195.82 341.67 185.67 341.67 179.69C341.67 175.37 345.16 171.88 349.48 171.88C371.25 171.88 428.75 171.88 450.52 171.88Z"
            id="cJV97X2PJ"
          ></path>
          <path
            d="M183.85 173.19C188.17 173.19 191.67 176.69 191.67 181C191.67 186.99 191.67 197.14 191.67 203.13C191.67 207.44 188.17 210.94 183.85 210.94C162.08 210.94 104.58 210.94 82.81 210.94C78.5 210.94 75 207.44 75 203.13C75 197.14 75 186.99 75 181C75 176.69 78.5 173.19 82.81 173.19C104.58 173.19 162.08 173.19 183.85 173.19Z"
            id="a5fpnITgN5"
          ></path>
          <path
            d="M150.52 93.75C154.84 93.75 158.33 97.25 158.33 101.56C158.33 107.55 158.33 117.7 158.33 123.68C158.33 128 154.84 131.5 150.52 131.5C128.75 131.5 71.25 131.5 49.48 131.5C45.16 131.5 41.67 128 41.67 123.68C41.67 117.7 41.67 107.55 41.67 101.56C41.67 97.25 45.16 93.75 49.48 93.75C71.25 93.75 128.75 93.75 150.52 93.75Z"
            id="a6i6qigxL6"
          ></path>
          <path
            d="M117.19 15.63C121.5 15.63 125 19.12 125 23.44C125 29.42 125 39.57 125 45.56C125 49.87 121.5 53.37 117.19 53.37C95.42 53.37 37.92 53.37 16.15 53.37C11.83 53.37 8.33 49.87 8.33 45.56C8.33 39.57 8.33 29.42 8.33 23.44C8.33 19.12 11.83 15.63 16.15 15.63C37.92 15.63 95.42 15.63 117.19 15.63Z"
            id="a1rhver8Q"
          ></path>
          <path
            d="M325.25 328.13C329.71 328.13 333.33 331.74 333.33 336.21C333.33 342.41 333.33 352.91 333.33 359.1C333.33 363.57 329.71 367.19 325.25 367.19C300.2 367.19 233.13 367.19 208.08 367.19C203.62 367.19 200 363.57 200 359.1C200 352.91 200 342.41 200 336.21C200 331.74 203.62 328.13 208.08 328.13C233.13 328.13 300.2 328.13 325.25 328.13Z"
            id="g34ToiL8md"
          ></path>
          <text
            id="c3OuGxpzDl"
            x="225"
            y="337.28"
            font-size="12"
            font-family="Roboto"
            font-weight="normal"
            font-style="normal"
            letter-spacing="0"
            alignment-baseline="before-edge"
            transform="matrix(1 0 0 1 8.33332812499998 -3.2523091634114962)"
            style="line-height: 100%"
            xml:space="preserve"
            dominant-baseline="text-before-edge"
          >
            <tspan
              x="225"
              dy="0em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              System Build/
            </tspan>
            <tspan
              x="225"
              dy="1.18em"
              alignment-baseline="before-edge"
              dominant-baseline="text-before-edge"
              text-anchor="start"
            >
            <!--eslint-disable-next-line  no-irregular-whitespace-->
              Configuration
            </tspan>
          </text>
        </defs>
        <g>
          <g>
            <g>
              <use
                xlink:href="#b19DRFVM6d"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#b19DRFVM6d"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g id="a7u6OdwSL">
              <use
                xlink:href="#g4s4wLBP95"
                opacity="1"
                fill="#ffffff"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#g4s4wLBP95"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#ffffff"
                  stroke-width="0.25"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#b5IJDvMEK"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#b5IJDvMEK"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#apK0NE1Lw"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#apK0NE1Lw"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#a7cdg6XJ8F"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#a7cdg6XJ8F"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#c12itXJmDH"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#c12itXJmDH"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#a10ZqzLOhy"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#a10ZqzLOhy"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#eb07NDsY2"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#eb07NDsY2"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#circle-three"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#circle-three"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#6ec6d8"
                  stroke-width="0.75"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#circle-four"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#circle-four"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#6ec6d8"
                  stroke-width="0.75"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g id="ewt3Qsl2">
              <use
                xlink:href="#a2qHqMBygG"
                opacity="1"
                fill="#000000"
                fill-opacity="1"
              ></use>
            </g>
            <g id="a2kjuA4hlr">
              <use
                xlink:href="#a61rZmEEq"
                opacity="1"
                fill="#000000"
                fill-opacity="1"
              ></use>
            </g>
            <g id="b2Sqp1GFnn">
              <use
                xlink:href="#b7mgtuHBLO"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#b7mgtuHBLO"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="0.25"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#b1odxFXxYf"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#b1odxFXxYf"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-opacity="0"
                ></use>
              </g>
            </g>
            <g id="a5X8YqCN7">
              <use
                xlink:href="#c2RLzacuNp"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#c2RLzacuNp"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="0.25"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g id="b17iPzijiS">
              <use
                xlink:href="#a1JE2Jj3Rj"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#a1JE2Jj3Rj"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="0.25"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g id="c6mJuB1HO">
              <use
                xlink:href="#an5BoormY"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#an5BoormY"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#000000"
                  stroke-width="0.25"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#aoQVoFOq9"
                opacity="1"
                fill="#ffffff"
                fill-opacity="0"
              ></use>
              <g>
                <use
                  xlink:href="#aoQVoFOq9"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#41a2b8"
                  stroke-width="1"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#h3nAkGu7LP"
                opacity="1"
                fill="#ffffff"
                fill-opacity="0"
              ></use>
              <g>
                <use
                  xlink:href="#h3nAkGu7LP"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#41a2b8"
                  stroke-width="1"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <g id="h8OH8GlgLS">
                <use
                  xlink:href="#b6v83mNlF"
                  opacity="1"
                  fill="#000000"
                  fill-opacity="1"
                ></use>
              </g>
              <g>
                <use
                  xlink:href="#circle-one"
                  opacity="1"
                  fill="#65696b"
                  fill-opacity="1"
                ></use>
                <g>
                  <use
                    xlink:href="#circle-one"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#6ec6d8"
                    stroke-width="0.75"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g id="cFZ7w78Kt">
                <use
                  xlink:href="#anbRDOwXu"
                  opacity="1"
                  fill="#65696b"
                  fill-opacity="1"
                ></use>
                <g>
                  <use
                    xlink:href="#anbRDOwXu"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#000000"
                    stroke-width="0.25"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g id="a2PrNjq4g7">
                <use
                  xlink:href="#a18JUikmgT"
                  opacity="1"
                  fill="#65696b"
                  fill-opacity="1"
                ></use>
                <g>
                  <use
                    xlink:href="#a18JUikmgT"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#000000"
                    stroke-width="0.25"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g>
                <use
                  xlink:href="#bKJWbjNQQ"
                  opacity="1"
                  fill="#ffffff"
                  fill-opacity="0"
                ></use>
                <g>
                  <use
                    xlink:href="#bKJWbjNQQ"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#41a2b8"
                    stroke-width="1"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g>
                <use
                  xlink:href="#d4Di1sIbX"
                  opacity="1"
                  fill="#ffffff"
                  fill-opacity="0"
                ></use>
                <g>
                  <use
                    xlink:href="#d4Di1sIbX"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#41a2b8"
                    stroke-width="1"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
            </g>
            <g>
              <g id="d1zbhduDk4">
                <use
                  xlink:href="#b5iHdYDTL"
                  opacity="1"
                  fill="#000000"
                  fill-opacity="1"
                ></use>
              </g>
              <g>
                <use
                  xlink:href="#circle-two"
                  opacity="1"
                  fill="#65696b"
                  fill-opacity="1"
                ></use>
                <g>
                  <use
                    xlink:href="#circle-two"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#6ec6d8"
                    stroke-width="0.75"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g id="d8PYaQQ4y">
                <use
                  xlink:href="#a2i5LkSNX"
                  opacity="1"
                  fill="#65696b"
                  fill-opacity="1"
                ></use>
                <g>
                  <use
                    xlink:href="#a2i5LkSNX"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#000000"
                    stroke-width="0.25"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g id="l19nn1tDu8">
                <use
                  xlink:href="#bangCP6nl"
                  opacity="1"
                  fill="#65696b"
                  fill-opacity="1"
                ></use>
                <g>
                  <use
                    xlink:href="#bangCP6nl"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#000000"
                    stroke-width="0.25"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g>
                <use
                  xlink:href="#cJV97X2PJ"
                  opacity="1"
                  fill="#ffffff"
                  fill-opacity="0"
                ></use>
                <g>
                  <use
                    xlink:href="#cJV97X2PJ"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#41a2b8"
                    stroke-width="1"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
              <g>
                <use
                  xlink:href="#a5fpnITgN5"
                  opacity="1"
                  fill="#ffffff"
                  fill-opacity="0"
                ></use>
                <g>
                  <use
                    xlink:href="#a5fpnITgN5"
                    opacity="1"
                    fill-opacity="0"
                    stroke="#41a2b8"
                    stroke-width="1"
                    stroke-opacity="1"
                  ></use>
                </g>
              </g>
            </g>
            <g>
              <use
                xlink:href="#a6i6qigxL6"
                opacity="1"
                fill="#ffffff"
                fill-opacity="0"
              ></use>
              <g>
                <use
                  xlink:href="#a6i6qigxL6"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#41a2b8"
                  stroke-width="1"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#a1rhver8Q"
                opacity="1"
                fill="#ffffff"
                fill-opacity="0"
              ></use>
              <g>
                <use
                  xlink:href="#a1rhver8Q"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#41a2b8"
                  stroke-width="1"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g>
              <use
                xlink:href="#g34ToiL8md"
                opacity="1"
                fill="#65696b"
                fill-opacity="1"
              ></use>
              <g>
                <use
                  xlink:href="#g34ToiL8md"
                  opacity="1"
                  fill-opacity="0"
                  stroke="#41a2b8"
                  stroke-width="1"
                  stroke-opacity="1"
                ></use>
              </g>
            </g>
            <g id="aaICicwO3">
              <use
                xlink:href="#c3OuGxpzDl"
                opacity="1"
                fill="#ffffff"
                fill-opacity="1"
              ></use>
            </g>
          </g>
        </g>
      </svg>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'GampVmodelAnimation',
};
</script>

<style>
  .vmodel-card {
    padding: 0px;
  }
</style>
