<script>

import {Line, mixins} from 'vue-chartjs';
const {reactiveProp} = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
      interval: null,
      counter: 0,
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style scoped>
 canvas {
  width: 100%;
  height: 100%;
}
</style>
