<template>
  <v-container>
    <v-row>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0" href="https://www.siemens.com/" target="_blank">
            <v-card-title class="align-center justify-center">
              <v-img :src="siemens" max-width="310px" class="siemens" contain/>
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-text class="text-left">
              {{siemensText}}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SiemensContent',
  data() {
    return {
      siemens: require('@/assets/capabilities/siemens.png'),
      siemensText: 'ControlSoft is proud to be a Siemens Solution Partner in Automation and Drives. At ControlSoft, we have expertise in utilizing Siemens hardware to a high degree of efficiency and effectiveness across a wide variety of industries and problem spaces, allowing us to deliver premium solutions to complicated projects in a timely fashion. ',
    };
  },
};
</script>

<style scoped>
.siemens {
  margin-right: 40px;
}
</style>
