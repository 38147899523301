<template>
  <v-card flat>
    <v-card-text class="flex-column">
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 290 290" width="100%" height="100%">
        <path fill="#6EC6D8" stroke="#a9acaf" stroke-width="3" class="logo-svg"
              d="
           M 156.50,34.85
           C 139.89,46.96 122.28,83.81 109.29,133.89
             109.29,133.89 107.04,142.39 107.04,142.39
             107.04,142.39 92.93,142.39 92.93,142.39
             92.93,142.39 78.69,142.39 78.69,142.39
             78.69,142.39 78.69,99.92 78.69,99.92
             78.69,99.92 78.69,57.45 78.69,57.45
             78.69,57.45 84.43,53.33 84.43,53.33
             103.67,39.72 128.65,31.72 152.88,31.35
             152.88,31.35 161.50,31.22 161.50,31.22
             161.50,31.22 156.50,34.85 156.50,34.85 Z
           M 245.55,70.82
           C 252.92,78.56 261.29,91.18 266.66,102.79
             270.91,111.91 277.28,134.02 277.28,139.51
             277.28,139.51 277.28,142.39 277.28,142.39
             277.28,142.39 238.06,142.39 238.06,142.39
             238.06,142.39 198.84,142.39 198.84,142.39
             198.84,142.39 200.59,135.02 200.59,135.02
             203.21,123.78 211.95,97.92 216.83,87.43
             227.57,64.32 235.06,59.95 245.55,70.82 Z
           M 68.69,104.92
           C 68.69,104.92 68.69,142.39 68.69,142.39
             68.69,142.39 51.21,142.39 51.21,142.39
             51.21,142.39 33.72,142.39 33.72,142.39
             33.72,142.39 33.72,137.64 33.72,137.64
             33.85,126.02 42.34,102.79 52.33,87.30
             57.70,78.94 66.82,67.45 68.20,67.45
             68.44,67.45 68.69,84.31 68.69,104.92 Z
           M 68.69,186.10
           C 68.69,224.07 69.32,222.07 58.95,216.83
             44.09,209.33 35.10,190.72 32.97,163.24
             32.97,163.24 32.10,152.38 32.10,152.38
             32.10,152.38 50.46,152.38 50.46,152.38
             50.46,152.38 68.69,152.38 68.69,152.38
             68.69,152.38 68.69,186.10 68.69,186.10 Z
           M 103.67,154.63
           C 103.67,160.12 91.93,191.35 85.68,202.59
             78.81,214.70 78.69,214.33 78.69,181.85
             78.69,181.85 78.69,152.38 78.69,152.38
             78.69,152.38 91.18,152.38 91.18,152.38
             102.29,152.38 103.67,152.63 103.67,154.63 Z
           M 277.53,161.12
           C 277.03,171.61 274.40,183.48 269.78,196.22
             253.17,241.68 213.58,271.53 164.49,275.53
             164.49,275.53 154.25,276.40 154.25,276.40
             154.25,276.40 157.87,272.16 157.87,272.16
             168.24,260.04 183.10,214.83 194.97,159.50
             194.97,159.50 196.47,152.38 196.47,152.38
             196.47,152.38 237.18,152.63 237.18,152.63
             237.18,152.63 277.90,153.00 277.90,153.00
             277.90,153.00 277.53,161.12 277.53,161.12 Z"/>
      </svg>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LogoAnimation',
};
</script>

<style scoped>

</style>
