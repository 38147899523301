<template>
    <Page name="About" class="about">
      <v-row>
        <Banner text="Overview"/>
      </v-row>
      <v-row justify="center">
        <v-col class="col-xl-7 col-lg-7 col-md-8 col-sm-12 col-xs-12">
          <v-card>
          <Stylevider/>
            <v-card-text>
              <Overview/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <Banner text="Our Leadership"/>
      </v-row>
      <v-row justify="center">
        <v-col class="col-xl-9 col-lg-7 col-md-9 col-sm-12 col-xs-12">
          <CardSlider/>
        </v-col>
      </v-row>
    </Page>
</template>

<script>
import Page from '../templates/Page/Page';
import Overview from '@/Pages/aboutus_content/Overview';
import CardSlider from '@/Pages/aboutus_content/CardSlider';
import Banner from '@/components/Banner';
import Stylevider from '@/components/Stylevider';
export default {
  name: 'About',
  components: {
    Stylevider,
    Banner,
    CardSlider,
    Overview,
    Page,
  },
  metaInfo: {
    title: 'About us',
    htmlAtts: {
      lang: 'en',
    },
  },
};
</script>

<style>
  .about p {
    font-size: medium;
  }
</style>
