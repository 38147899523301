<template>
  <v-card flat light>
    <v-card-text class="flex-column --theme-light" >
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 -57 744 744"
           width="100%" height="100%"
           class="middle-svg">
        <path class="landing-animation"
              fill="none" stroke="black" stroke-width="2"
              d="M 448.78,486.60
           C 448.78,486.60 448.78,568.96 448.78,568.96
             448.78,568.96 294.43,568.96 294.43,568.96
             294.43,568.96 294.43,486.60 294.43,486.60
             294.43,486.60 448.78,486.60 448.78,486.60 Z
           M 56.21,79.05
           C 56.21,426.10 89.50,505.26 145.94,426.10
             194.15,358.48 169.92,290.37 282.54,100.75
             328.72,23.00 318.70,334.23 358.88,395.48M 56.21,284.47
           C 56.21,284.47 376.29,284.47 376.29,284.47M 684.32,463.10
           C 684.32,463.10 684.32,428.65 684.32,428.65
             684.32,428.65 464.68,428.65 464.68,428.65
             464.68,428.65 464.68,465.65 464.68,465.65M 684.32,242.37
           C 684.32,242.37 684.32,400.58 684.32,400.58
             684.32,400.58 464.68,400.58 464.68,400.58
             464.68,400.58 464.68,242.37 464.68,242.37
             464.68,242.37 684.32,242.37 684.32,242.37 Z
           M 684.32,52.26
           C 684.32,52.26 684.32,210.47 684.32,210.47
             684.32,210.47 464.68,210.47 464.68,210.47
             464.68,210.47 464.68,52.26 464.68,52.26
             464.68,52.26 684.32,52.26 684.32,52.26 Z
           M 716.46,26.74
           C 716.46,26.74 716.46,486.07 716.46,486.07
             716.46,486.07 26.75,486.07 26.75,486.07
             26.75,486.07 26.75,26.74 26.75,26.74
             26.75,26.74 716.46,26.74 716.46,26.74 Z
           M 56.24,52.21
           C 56.24,52.21 56.24,466.11 56.24,466.11M 411.02,559.29
           "/>
      </svg>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ScadaAnimation',
};
</script>

<style scoped>

</style>
