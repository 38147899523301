<template>
  <v-container>
    <v-row align="center" justify="center" no-gutters>
      <v-col align-self="center">
        <h1 class="text-center">Target Any Platform</h1>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col v-for="(item, i) in targets" :key="i" align-self="center">
        <v-card elevation="0">
          <v-card-title class="justify-center align-center">
            <v-icon x-large :color="item.color">
              mdi-{{ item.name }}
            </v-icon>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PlatformTargets',
  data() {
    return {
      targets: [
        {
          name: 'microsoft-windows',
          color: '#00a2ed',
          tooltip: 'Microsoft Windows',
        },
        {
          name: 'android',
          color: '#a4c639',
          tooltip: 'Android',
        },
        {
          name: 'apple-ios',
          color: 'black',
          tooltip: 'ios 14',
        },
        {
          name: 'linux',
          color: '#333333',
          tooltip: '*nix Systems',
        },
        {
          name: 'desktop-mac',
          color: 'black',
          tooltip: 'MacOS',
        },
        {
          name: 'firefox',
          color: '#E66000',
          tooltip: 'Mozilla Firefox',
        },
        {
          name: 'google-chrome',
          color: '#4285f4',
          tooltip: 'Google Chrome & Chromium',
        },
        {
          name: 'microsoft-azure',
          color: '#00a2ed',
          tooltip: 'Microsoft Azure',
        },
        {
          name: 'aws',
          color: '#FF9900',
          tooltip: 'Amazon Web Services',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
