<template>
  <v-container>
    <RockwellContent/>
    <KukaContent/>
    <SiemensContent/>
    <RaspberryPiContent/>
    <v-divider></v-divider>
  </v-container>
</template>

<script>

import KukaContent from '@/Pages/services_content/capabilities/automation_content/KukaContent';
import RockwellContent from '@/Pages/services_content/capabilities/automation_content/RockwellContent';
import SiemensContent from '@/Pages/services_content/capabilities/automation_content/SiemensContent';
import RaspberryPiContent from '@/Pages/services_content/capabilities/automation_content/RaspberryPiContent';

export default {
  name: 'AutomationPartnersContainer',
  components: {KukaContent, RockwellContent, SiemensContent, RaspberryPiContent},
};
</script>

<style scoped>
.kuka {
  padding-top: 30px;
  padding-bottom: 45px;
}
.siemens {
  bottom: 25px;
}
.siemens-text {
  bottom: 25px;
}
.height-adjust {
  height: 120px;
}
</style>
