<template>
  <v-container>
    <v-row>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-title class="align-center justify-center">
              <v-img :src="eplan" contain max-width="310px" class="eplan"/>
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-text class="text-left">
              {{eplanText}}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EPlanContent',
  data() {
    return {
      eplan: require('@/assets/capabilities/electrical_content/eplan.png'),
      eplanText: 'EPLAN Pro Panel gives you a stable foundation for automating and industrialising your panel building. It enables 3D design and construction of control cabinets, switchgear systems and power distribution systems for energy distribution. EPLAN Pro Panel provides you an integrated solution for control cabinet design',
    };
  },
};
</script>

<style scoped>
.eplan {
  margin-right: 40px;
}

</style>
