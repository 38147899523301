<template>
  <Page name="Funding" class="funding">
    <v-row no-gutters>
      <Stylevider/>
      <v-col cols="12">
        <v-card>
          <v-card-title class="justify-center"><h1 class="accent--text">Funding & Support</h1></v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="text-center title">
            ControlSoft are honoured to be supported by Ireland’s European Structural and Investments Funds Programmes
            2014-2020, the European Regional Development Fund and Enterprise Ireland.
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-img :src="funding"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                    <h2 class="text-center">With their support, <b class="accent--text">ControlSoft</b> have been able to
                      engage in the following <b class="accent--text">R&D</b> initiatives
                    </h2>
                  <v-divider></v-divider>
                  <ChiefFinancialOfficer/>
                  <v-divider></v-divider>
                  <Platform/>
                  <v-divider></v-divider>
                  <ChiefTechnicalOfficer/>
                  <v-divider></v-divider>
                  <GraduateAutomationEngineers/>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <Stylevider/>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from '../templates/Page/Page';
import Platform from '@/Pages/funding_content/Platform';
import ChiefFinancialOfficer from '@/Pages/funding_content/ChiefFinancialOfficer';
import ChiefTechnicalOfficer from '@/Pages/funding_content/ChiefTechnicalOfficer';
import GraduateAutomationEngineers from '@/Pages/funding_content/GraduateAutomationEngineers';
import Stylevider from '@/components/Stylevider';

export default {
  name: 'Funding',
  metaInfo: {
    title: 'Funding',
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {
    Stylevider,
    GraduateAutomationEngineers,
    ChiefFinancialOfficer,
    ChiefTechnicalOfficer,
    Platform,
    Page,
  },
  data() {
    return {
      funding: require('@/assets/funding.png'),
    };
  },
};
</script>

<style>
.funding p {
  font-size: medium;
}

.funding h1, h2, h3 {
  text-transform: uppercase;
}

.funding .padding-left-null {
  margin-left: 0 !important;
}
</style>
