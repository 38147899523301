<template>
  <v-card-text>
    <h2>Client</h2>
    <p>
      The client is involved in the water treatment sector. Using unique technology they provide waste water treatment
      solutions to a variety of other industries, tailoring their product to each customers individual requirements.
    </p>
    <h2>Project Summary</h2>
    <p>
      The process used by the client involves an Anaerobic Digester system to treat water used in a separate production
      process. The digester would remove waste and harmful bacteria from effluent and burn off excess gasses.
    </p>
    <p>The project required:</p>
    <ul>
      <li>Variable speed pump control</li>
      <li>Control of automatic valves</li>
      <li>Monitoring and trending of various instruments such as:</li>
      <li>Flow meters</li>
      <li>Level Sensors</li>
      <li>pH, suspended solids and other chemical transmitters</li>
      <li>Pressure & temperature transmitters</li>
    </ul>
    <h2>Solution</h2>
    <p>
      A careful choice of hardware from Siemens was coupled with adaptable, reusable PLC code to carry out the process
      safely. A Siemens HMI with intuitive and practical visualisation was used to monitor and control all pumps and
      instruments, as well as provide real time data trending and analytics
    </p>
    <h2>Results</h2>
    <p>
      Use of standardised and customizable code and HMI graphics provided a flexible, modular system that decreases
      complexity and simplifies commissioning while remaining open to expansion.
    </p>
  </v-card-text>
</template>

<script>
export default {
  name: 'NVPEnergyStudy',
};
</script>

<style scoped>
ul {
  padding-bottom: 12px;
}
li {
  padding-top:0;
  padding-bottom: 3px;
}
p{
  padding-top:0;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
</style>
