<template>
  <v-container>
    <EPlanContent/>
    <RittalContent/>
    <v-divider></v-divider>
  </v-container>
</template>

<script>

import EPlanContent from '@/Pages/services_content/capabilities/electrical_content/EPlanContent';
import RittalContent from '@/Pages/services_content/capabilities/electrical_content/RittalContent';

export default {
  name: 'ElectricalPartnersContainer',
  components: {EPlanContent, RittalContent},
};
</script>

<style scoped>
.rittal {
  padding-top: 30px;
  padding-bottom: 45px;
}
.height-adjust {
  height: 120px;
}
</style>
