<template>
  <v-col class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
    <v-dialog v-model="dialog" :fullscreen="isMobile()" max-width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-layout justify-center>
        <v-btn
            :class="text"
            rounded
            width="230px"
            elevation="0"
            x-large
            color="transparent"
            ripple
            @click="updateUrl()"
            v-bind="attrs"
            v-on="on">
          <v-icon x-large :color="iconColor">mdi-{{ icon }}</v-icon>
          <div class="pl-2">{{text}}</div>
        </v-btn>
      </v-layout>
      </template>
      <v-card class="elevation-0">
        <v-layout justify-end>
          <v-btn icon right color="red" @click="dialog = false">
            <v-icon large>
              mdi-close-box
            </v-icon>
          </v-btn>
        </v-layout>
        <Banner :text="BannerText"/>
        <v-divider class="pb-3"></v-divider>
        <v-card-text>
          <div class="pl-3 industry">
            <slot></slot>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import Banner from '@/components/Banner';
import eventBus from '@/eventBus';

export default {
  name: 'IndustryContent',
  components: {Banner},
  props: ['icon', 'iconColor', 'BannerText', 'text'],
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    eventBus.$on('dialog-activate', (id) => {
      if (this.text.toLowerCase() == id.toLowerCase()) {
        this.dialog = true;
      }
    });
  },
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
    },
    updateUrl() {
      eventBus.$emit('update-url', this.text);
    },
  },
};
</script>

<style>
  .industry h2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .industry * {
    padding-top: 4px;
    padding-bottom: 3px;
  }
</style>
