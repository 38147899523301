<template>
  <v-sheet color="accent" height="120px">
    <p class="heading white--text text-center jtext">
      <v-icon v-if="!isMobile()" class="mr-3">mdi-{{ this.icons[iconIndex] }}</v-icon>
      <b class="jumbotron"></b>
    </p>
  </v-sheet>
</template>

<script>
import Typewriter from 'typewriter-effect/dist/core';

export default {
  name: 'Jumbotron',
  data() {
    return {
      iconIndex: null,
      icons: [
        'keyboard-variant',
        'puzzle',
        'robot',
        'car-battery',
        'wan',
        'cellphone-link',
      ],
    };
  },
  methods: {
    alterIcon() {
      if (this.iconIndex === null) {
        this.iconIndex = 0;
      } else {
        this.iconIndex += 1;

        if (this.iconIndex === this.icons.length) {
          this.iconIndex = 0;
        }
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  mounted() {
    new Typewriter('.jumbotron', {loop: true, delay: 45})
        .callFunction(this.alterIcon, this)
        .typeString('Industrial Automation & Control')
        .pauseFor(2000)
        .deleteAll()
        .callFunction(this.alterIcon, this)
        .typeString('Consulting & Integration Services')
        .pauseFor(2000)
        .deleteAll()
        .callFunction(this.alterIcon, this)
        .typeString('Robotics')
        .pauseFor(2000)
        .deleteAll()
        .callFunction(this.alterIcon, this)
        .typeString('Energy Management')
        .pauseFor(2000)
        .deleteAll()
        .callFunction(this.alterIcon, this)
        .typeString('Networking & Infastructure')
        .pauseFor(2000)
        .deleteAll()
        .callFunction(this.alterIcon, this)
        .typeString('Software Engineering')
        .pauseFor(2000)
        .deleteAll()
        .start();
  },
};
</script>

<style scoped>
  .jtext {
    font-size: 2em;
    padding-top: 30px;
  }
</style>
