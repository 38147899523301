<template>
  <div>
    <v-chip
        class="ma-2 padding-left-null"
        color="accent"
        label

        text-color="white">
      <v-icon left>mdi-account-plus</v-icon>
      Chief Technical Officer
    </v-chip>
    <p>
      A Key Manager has been co-funded in this company by the European Regional Development Fund and
      Enterprise Ireland under the Border, Midland and Western Regional Operational Programme 2014-2020.
    </p>

    <h3>Aims</h3>
    <p>
      To recruit a Key Manager who will bring skills into the company that are critical to future growth.
    </p>


    <h3>Results</h3>
    <p>
      The Key Manager in the role of Chief Technical Officer has commenced employment and is
      contributing to improvements in company productivity and to changes in output to meet defined market
      requirements. Our in-house development of software systems and methods has already proven to
      increase our efficiencies and quality of our services.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ChiefTechnicalOfficer',
};
</script>

<style scoped>

</style>
