<template>
  <div>
    <v-container fluid>
      <v-row justify="center" v-for="slideRow in slideRows" :key="slideRow.row">
        <v-col v-for="slide in slideRow.slide" :key="slide.title" class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <v-layout justify-center>
            <CardSlide
                class="pa-2"
                :title="slide.title"
                :subtitle="slide.subtitle"
                :text="slide.text"
                :src="slide.src"/>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardSlide from '@/Pages/aboutus_content/CardSlide';

export default {
  name: 'CardSlider',
  components: {CardSlide},
  data() {
    return {
      slideRows: [
        {
          row: 1,
          slide: [
            {
              src: require('@/assets/avatars/dermot.jpg'),
              title: 'Dermot Carragher',
              subtitle: 'Managing Director',
              text: 'Dermot holds a  BEng in Electronic Engineering and has 20+ years’ experience with industrial automation in multiple sectors worldwide. Dermot has been responsible for overseeing projects and the development of key control systems.',
            },
            {
              src: require('@/assets/avatars/joe.jpg'),
              title: 'Joe McGill',
              subtitle: 'Technical Director',
              text: 'Joe has 20+ years’ experience with industrial automation in multiple sectors worldwide. Joe is a leading Technical Expert in PLC/PAC, Software including Scada, OEE, BI, and MES, Industrial Network design and security.',
            },
            {
              src: require('@/assets/avatars/peter.jpg'),
              title: 'Peter McGinnity',
              subtitle: 'Operations Director',
              text: 'Peter holds a BEng in Electronic Engineering from DCU. Peter has over 15 years’ experience with industrial automation systems in multiple sectors worldwide. Peter has also been responsible for key customer accounts and the daily running of the business.',
            },
            {
              src: require('@/assets/avatars/yvonne.jpg'),
              title: 'Yvonne Fox',
              subtitle: 'Resourcing Manager',
              text: 'Yvonne has 25+ years’ experience in People Management, Project Planning & Resourcing across multiple sectors. Responsible for the Resource Planning within the business and also Quality Management Systems Champion for ISO 9001.',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
