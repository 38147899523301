<template>
  <v-card flat>
    <v-card-text>
      <svg xmlns="http://www.w3.org/2000/svg"
           width="100%" height="100%"
           viewBox="0 30 744 744" class="middle-svg">
        <path
            class="second-svg"
            fill="none" stroke="black" stroke-width="2"
            d="M 131.59,116.88
           C 131.25,115.34 131.25,113.73 131.59,112.19
             132.05,110.14 133.11,108.24 134.51,106.68
             135.92,105.11 137.67,103.87 139.55,102.93
             143.31,101.04 147.54,100.33 151.71,99.73
             155.88,99.14 160.11,98.61 164.01,97.03
             165.97,96.25 167.82,95.20 169.41,93.82
             171.00,92.44 172.32,90.72 173.10,88.76
             173.84,86.94 174.10,84.93 173.94,82.97
             173.78,81.01 173.22,79.09 172.36,77.32
             170.65,73.77 167.83,70.86 164.72,68.45
             158.49,63.63 150.89,60.50 145.65,54.62
             141.30,49.74 138.93,43.15 139.17,36.61
             139.42,30.08 142.28,23.68 146.99,19.14M 105.12,113.13
           C 104.78,111.59 104.78,109.98 105.12,108.44
             105.58,106.39 106.64,104.49 108.05,102.93
             109.45,101.36 111.20,100.12 113.08,99.18
             116.84,97.29 121.07,96.58 125.24,95.98
             129.41,95.39 133.64,94.86 137.55,93.29
             139.50,92.50 141.36,91.45 142.94,90.07
             144.53,88.69 145.85,86.97 146.63,85.02
             147.37,83.19 147.63,81.19 147.47,79.22
             147.31,77.26 146.75,75.34 145.89,73.57
             144.18,70.02 141.36,67.11 138.25,64.70
             132.02,59.88 124.42,56.75 119.18,50.88
             114.83,46.00 112.46,39.40 112.70,32.87
             112.95,26.33 115.81,19.93 120.52,15.40M 374.67,651.51
           C 374.67,651.51 24.10,651.51 24.10,651.51
             24.10,651.51 24.10,465.24 24.10,465.24
             24.10,465.24 70.97,465.24 70.97,465.24
             70.97,465.24 70.97,129.87 70.97,129.87
             70.97,129.87 159.35,129.87 159.35,129.87
             159.35,129.87 159.35,466.58 159.35,466.58
             159.35,466.58 374.95,466.58 374.95,466.58
             374.95,466.58 374.67,651.51 374.67,651.51M 476.78,651.78
           C 476.78,651.78 476.78,532.78 476.78,532.78
             476.78,532.78 589.43,532.78 589.43,532.78
             589.43,532.78 589.43,651.78 589.43,651.78
             589.43,651.78 476.78,651.78 476.78,651.78 Z
           M 374.95,467.25
           C 374.95,467.25 687.73,282.78 687.73,282.78
             687.73,282.78 687.73,652.48 687.73,652.48
             687.73,652.48 374.95,651.74 374.95,651.74
             374.95,651.74 374.95,467.25 374.95,467.25 Z"/>
      </svg>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FactoryAnimation',
};
</script>

<style scoped>

</style>
