<template>
  <v-container class="energy-header">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="1">
        <v-icon>
          mdi-{{icon}}
        </v-icon>
      </v-col>
      <v-col cols="11">
        <v-progress-linear color="green" :value="value" buffer-value="100"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EnergyHeader',
  data() {
    return {
      value: 5,
      up: true,
      icon: 'battery-low',
    };
  },
  mounted() {
    setInterval(() => {
      if (this.up) {
        this.value += 10;
      } else {
        this.value -= 10;
      }

      if (this.value < 25) {
        this.icon = 'battery-low';
      } else if (this.value > 25 && this.value < 75) {
        this.icon = 'battery-medium';
      } else if (this.value > 75) {
        this.icon = 'battery-high';
      }

      if (this.value > 100) {
        this.up = false;
      }

      if (this.value < 1) {
        this.up = true;
      }
    }, 300);
  },
};
</script>

<style scoped>
  .energy-header {
    width: 100%;
  }
</style>
