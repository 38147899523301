<template>
  <Page class="404page">
    <v-row align="center" justify="center" cols="12" no-gutters>
      <v-col>
        <v-layout align-center justify-center>
          <v-icon x-large color="red">
            mdi-help-rhombus
          </v-icon>
        </v-layout>
        <h1 class="text-center">Hmm... did you get lost?</h1>
        <v-layout align-center justify-center>
          <router-link to="/">Click here to navigate back home</router-link>
        </v-layout>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from '@/templates/Page/Page';
export default {
  name: 'NotFound',
  components: {Page},
};
</script>

<style scoped>

</style>
