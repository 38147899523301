<template>
  <v-container>
    <v-row>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0" href="https://www.rockwellautomation.com/" target="_blank">
            <v-card-title class="align-center justify-center">
              <v-img :src="rockwell" contain max-width="389px" class="rockwell"/>
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-text class="text-left">
              {{rockwellText}}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RockwellContent',
  data() {
    return {
      rockwell: require('@/assets/capabilities/rockwell.png'),
      rockwellText: 'The community of Rockwell Automation Recognized System Integrators ensures that, through loyal partnership between both parties, projects are executed per industry standards and provide domain expertise to the industry. Recognized System Integrators are qualified partners who can help you solve production challenges by advising on and delivering the best solution with the lowest amount of risk.',
    };
  },
};
</script>

<style scoped>
.rockwell {
  margin-right: 40px;
}

</style>
