<template>
  <Page class="careers">
    <v-row align="center" justify="center">
      <Banner text="Available Positions" />
    </v-row>
    <v-dialog
      v-model="dialog"
      width="600"
      transition="dialog-bottom-transition"
    >
      <career-posting :markdown="markdown" />
    </v-dialog>
    <v-container fluid>
      <br>
      <v-row justify="center" align="center">
        <v-divider></v-divider>
        <h2>Administration</h2>
        <v-divider></v-divider>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority=""
            title="Financial Controller"
            description="Develop and maintain a strong financial control environment"
            icon="desktop-classic"
            animation="wiggle"
            id="administration_financial_controller"
          />
        </v-col>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-divider></v-divider>
        <h2>Automation</h2>
        <v-divider></v-divider>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Senior"
            title="Automation Engineer"
            description="Develop, commission and support automation control systems"
            icon="cog"
            animation="spin"
            id="automation_senior"
          />
        </v-col>        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Intermediate"
            title="Automation Engineer"
            description="Develop, commission and support automation control systems"
            icon="cog"
            animation="spin"
            id="automation_intermediate"
          />
        </v-col>
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <job-list-item
              v-on:display-job="onDisplayJob"
              seniority="Graduate"
              title="Automation Engineer"
              description="Develop, commission and support automation control systems"
              icon="cog"
              animation="spin"
              id="automation_graduate"
            />
        </v-col>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Senior"
            title="Mechatronic Engineer"
            description="Develop, commission and support automation control systems"
            icon="cog"
            animation="spin"
            id="mechatronic_senior"
          />
        </v-col>
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Intermediate"
            title="Mechatronic Engineer"
            description="Develop, commission and support automation control systems"
            icon="cog"
            animation="spin"
            id="mechatronic_intermediate"
          />
        </v-col>
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Graduate"
            title="Mechatronic Engineer"
            description="Develop, commission and support automation control systems"
            icon="cog"
            animation="spin"
            id="mechatronic_graduate"
          />
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-divider></v-divider>
        <h2>Electrical</h2>
        <v-divider></v-divider>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Senior"
            title="Electrical Panel Build Engineer"
            description="Develop, commission and support automation control systems"
            icon="connection"
            animation="wiggle"
            id="electrical_senior"
          />
        </v-col>
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Intermediate"
            title="Electrical Design Engineer"
            description="Develop, commission and support automation control systems"
            icon="connection"
            animation="wiggle"
            id="electrical_design_engineer"
          />
        </v-col>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-divider></v-divider>
        <h2>Software</h2>
        <v-divider></v-divider>
      </v-row>
      <br>
      <v-row justify="center" align="center">
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Senior"
            title="Software Engineer (UI/UX Focused)"
            description="Develop new and existing products and services"
            icon="desktop-classic"
            animation="wiggle"
            id="software_uiux_senior"
          />
        </v-col>
        <!--<v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Senior"
            title="Software Engineer (UI/UX Focused)"
            description="Develop new and existing products and services"
            icon="desktop-classic"
            animation="wiggle"
            id="software_uiux_senior"
          />
        </v-col>
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Senior"
            title="Software Engineer"
            description="Develop new and existing products and services"
            icon="desktop-classic"
            animation="wiggle"
            id="software_senior"
          />
        </v-col>
        <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Intermediate"
            title="2x Software Engineer"
            description="Support the production of products and services"
            icon="desktop-classic"
            animation="wiggle"
            id="software_intermediate"
          />
        </v-col> -->
        <!-- <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <job-list-item
            v-on:display-job="onDisplayJob"
            seniority="Graduate & Above"
            title="Manual QA Tester"
            description="Support the production of products and services"
            icon="desktop-classic"
            animation="wiggle"
            id="manual_qa_tester"
          />
        </v-col> -->
      </v-row>
      <br>
      <v-row>
        <v-layout align-center justify-center>
            <v-btn class="button accent" large @click="mail"> Apply Now </v-btn>
        </v-layout>
      </v-row>
    </v-container>
  </Page>
</template>

<script>
import Page from '@/templates/Page/Page';
import JobListItem from './career_content/JobListItem.vue';
import CareerPosting from '../components/CareerPosting.vue';
import Banner from '@/components/Banner';

export default {
  name: 'Careers',
  components: {Page, JobListItem, CareerPosting, Banner},
  metaInfo: {
    title: 'Careers',
    htmlAtts: {
      lang: 'en',
    },
  },
  methods: {
    onDisplayJob(markdown) {
      this.markdown = markdown;
      this.dialog = true;
    },
    mail() {
      window.location.href = 'mailto:careers@controlsoft.ie';
    },
  },
  data() {
    return {
      dialog: false,
      markdown: '',
    };
  },
};
</script>

<style scoped>
h2 {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
