<template>
  <IndustryContent text="Data Centers"
                   icon="database"
                   banner-text="Data Center Case Study"
                   icon-color="red">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>

    <h2>Client</h2>
    <p>
      The client is a global leader in the design, provision & maintenance of Data Centres the world over.
    </p>
    <h2>Project Summary</h2>
    <p>
      The scope of work involved providing a means of monitoring a facility’s critical services in a secure and user
      friendly fashion.
    </p>
    <ul>
      <li>Around the clock access to vital statistics and measurements critical to maintaining server up time.</li>
      <li>Bespoke reports that could be scheduled and emailed to key staff in order to keep them informed of the
        facility’s performance.
      </li>
      <li>A secure means of allowing trained staff to access the information they need wherever they may be on site.
      </li>
      <li>A historical database consisting of vital measurements & events to allow the troubleshooting of issues and
        regular health checks.
      </li>
      <li>The means of alerting relevant staff of real time events to instigate recovery procedures and reduce down
        time.
      </li>
    </ul>
    <h2>Solution</h2>
    <p>
      The solution was to use Allen Bradley hardware and Rockwell Automation software.
      The hardware, consisting of ControlLogix PLCs, would obtain the required information from meters, generators, UPS’
      and breakers over a number of communication protocols including Modbus TCP, Modbus RTU & Ethernet IP.
    </p>
    <p>
      This information would then be displayed on a web based SCADA system utilising both FactoryTalk SE and FactoryTalk
      Viewpoint to allow visibility to verified user accounts anywhere on site.
      The database was created using FactoryTalk Historian to enable fast integration and additional features in
      conjunction with the other Rockwell Automation software used. This data was then collated and visualised using
      Report Builder to create bespoke reports to meet the client’s needs.
    </p>
    <h2>Results</h2>
    <p>
      The system successfully met all of the clients requirements and did so in a modular yet flexible fashion.
      This allowed for further projects to be completed at an accelerated rate while also allowing new site specific
      requests/changes to be achievable thus keeping the clients and all involved satisfied with the end product.
    </p>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';

export default {
  name: 'DataServed',
  components: {IndustryContent},
  data() {
    return {
      img: require('@/assets/industries_served/datacenter.jpg'),
    };
  },
};
</script>

<style scoped>

</style>
