<template>
  <v-sheet height="120px" width="100%" class="banner text-center" color="accent">
    <h1 class="white--text">{{ text }}</h1>
    <h3 class="white--text">{{ subtitle}}</h3>
  </v-sheet>
</template>

<script>
export default {
  name: 'Banner',
  props: ['text', 'subtitle'],
};
</script>

<style scoped>
.banner {
  padding-top: 30px;
}
</style>
