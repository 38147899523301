<template>
  <v-card class="text-center d-flex flex-column">
    <Stylevider/>
    <h2>Business Hours</h2>
    <v-card-text class="flex">
      <v-simple-table dense class="text-center flex">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">Day</th>
            <th class="text-center">Hours</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in days" :key="item.name">
            <td>{{ item.day }}</td>
            <td :style="item.style">{{ item.time }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Stylevider from '@/components/Stylevider';
export default {
  name: 'OpeningHours',
  components: {Stylevider},
  data() {
    return {
      days: [
        {
          day: 'Monday',
          time: '08:30-17:30',
        },
        {
          day: 'Tuesday',
          time: '08:30-17:30',
        },
        {
          day: 'Wednesday',
          time: '08:30-17:30',
        },
        {
          day: 'Thursday',
          time: '08:30-17:30',
        },
        {
          day: 'Friday',
          time: '08:30-16:30',
          style: 'text-decoration: underline;',
        },
        {
          day: 'Saturday',
          time: 'closed',
          style: 'font-weight: bold;',
        },
        {
          day: 'Sunday',
          time: 'closed',
          style: 'font-weight: bold;',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
