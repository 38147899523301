<template>
  <div>
    <v-app-bar flat color="transparent" class="pt-2">
        <div v-if="!this.isMobile()" class="d-flex align-center pa-6" >
          <v-img :src="logo" contain alt="ControlSoft Automation Systems Limited" @click="$router.push({path: '/'})"/>
        </div>
        <v-tabs v-model="tab" right center-active hide-slider active-class="active-tab">
          <v-tab v-for="item in items" :key="item.text" :to="item.to" :class="item.text == 'Industries' ? 'industry-tab' : ''">
            <button v-if="item.text != 'Industries'">
              <v-icon>{{item.icon}}</v-icon>
              <p>{{item.text}}</p>
            </button>
            <v-menu v-else :close-on-content-click="false" :nudge-left="60" offset-y :transition="false">
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" :to="item.to">
                  <v-icon>{{item.icon}}</v-icon>
                  <p>{{item.text}}</p>
                </button>
              </template>
              <v-list>
                <v-list-item v-for="(industry, index) in industries" :key="index">
                  <v-tab v-model="tab" right center-active hide-slider :to="'/industry/' + (industry.to == null ? industry.name : industry.to)">
                    <v-icon :color="industry.color" left>mdi-{{industry.icon}}</v-icon>
                    <p style="margin-top:14px">{{industry.name}}</p>
                  </v-tab>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
        </v-tabs>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'TopNavigationMenu',
  data() {
    return {
      tab: 0,
      logo: require('@/assets/logo.png'),
      mobileLogo: require('@/assets/mobile-logo.png'),
      items: [
        {
          text: 'Home',
          to: '/',
          icon: 'mdi-home',
        },
        {
          text: 'Services',
          to: '/services',
          icon: 'mdi-cogs',
        },
        {
          text: 'Industries',
          to: '',
          icon: 'mdi-factory',
        },
        {
          text: 'About',
          to: '/about',
          icon: 'mdi-information',
        },
        {
          text: 'Contact',
          to: '/contact',
          icon: 'mdi-phone',
        },
      ],
      industries: [
        {
          to: '',
          name: 'Industries',
          icon: 'factory',
        },
        {
          name: 'Pharmaceutical',
          icon: 'needle',
          color: 'blue',
        },
        {
          name: 'Data Centers',
          icon: 'database',
          color: 'red',
        },
        {
          name: 'Food',
          icon: 'food',
          color: 'green',
        },
        {
          name: 'Construction',
          icon: 'account-hard-hat',
          color: 'orange',
        },
        {
          name: 'Mining & Minerals',
          icon: 'pickaxe',
          color: 'purple',
        },
        {
          name: 'Packaging',
          icon: 'mailbox-up-outline',
          color: 'brown',
        },
        {
          name: 'Life Sciences',
          icon: 'heart-plus',
          color: 'pink',
        },
        {
          name: 'Nutrition',
          icon: 'molecule',
          color: 'cyan',
        },
        {
          name: 'Bio-Sciences',
          icon: 'flask-outline',
          color: 'lime',
        },
        {
          name: 'Marine',
          icon: 'ferry',
          color: 'teal',
        },
      ],
    };
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
};
</script>

<style scoped>
  .active-tab {
    color: #6EC6D8;
    border-bottom-color: #6EC6D8;
    border-bottom-width: 3px;
  }

  .industry-tab {
    color: #65696B;
  }

  .industry-tab:hover {
    color: #6EC6D8;
  }

  .v-icon--left {
    margin-right: 18px;
  }

  button {
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    text-align: center;
    text-transform: uppercase;
  }
</style>
