<template>
  <v-layout justify-center>
    <v-footer color="transparent" justify="center" class="text-center" max-width="100%">
      <v-container>
        <v-row>
          <v-col v-for="item in items" :key="item.href">
            <span>
              <router-link :to="item.href">{{item.text}}</router-link>
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn v-for="(item, i) in icons"
                :key="i"
                class="mx-4"
                icon
                :href="item.link"
                target="_blank">
              <v-icon size="24px" :style="'color:' + item.color">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Site crafted by <b class="primary--text">Control</b><b class="accent--text">Soft</b> Automation Systems software team.</p>
            <p><b>&copy;</b> <b class="primary--text">Control</b><b class="accent--text">Soft</b> Automation Systems Limited
              {{ new Date().getFullYear() }}</p>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="3">
            <v-layout align-center justify-center>
              <v-img contain :src="iso"/>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-layout>
</template>

<script>
export default {
  text: 'PageFooter',
  data: () => ({
    items: [
      {
        href: '/',
        text: 'Home',
      },
      {
        href: '/careers',
        text: 'Careers',
      },
      {
        href: '/funding',
        text: 'Funding',
      },
      {
        href: '/privacy',
        text: 'Privacy',
      },
      {
        href: '/eula',
        text: 'EULA',
      },
    ],
    iso: require('./../../assets/iso.png'),
    icons: [
      {
        icon: 'mdi-linkedin',
        link: 'https://www.linkedin.com/company/controlsoft-automation-systems-ltd/',
        color: '#0e76a8',
      },
      {
        icon: 'mdi-youtube',
        link: 'https://www.youtube.com/channel/UCmvDvSSCdjVupot7DnvoZtA',
        color: '#c4302b',
      },
      {
        icon: 'mdi-github',
        link: 'https://github.com/ControlSoft-Automation',
        color: '#000000',
      },
    ],
  }),
};
</script>

<style scoped>

</style>
