<template>
  <IndustryContent icon-color="pink"
                   icon="heart-plus"
                   text="Life Sciences"
                   banner-text="Life Sciences - Client Case Study">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <v-tabs v-model="tab" background-color="transparent" color="accent" grow>
      <v-tab v-for="item in items" :key="item.name">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.id">
        <v-card flat>
          <v-card-text>
            <div v-if="item.id === 1">
              <TakedaStudy/>
            </div>
            <div v-else>
              <NVPEnergyStudy/>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';
import TakedaStudy from '@/Pages/services_content/industries_served/client_studies/takedaStudy';
import NVPEnergyStudy from '@/Pages/services_content/industries_served/client_studies/nvpEnergyStudy';

export default {
  name: 'LifeScienceServed',
  components: {IndustryContent, TakedaStudy, NVPEnergyStudy},
  data() {
    return {
      img: require('@/assets/industries_served/lifescience.jpg'),
      tab: null,
      items: [
        {
          name: 'Takeda',
          id: 1,
        },
        {
          name: 'NVP Energy',
          id: 2,
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
