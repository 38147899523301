<template>
  <v-card-text>
    <h2>Client</h2>
    <p>
      Takeda is one of the largest pharmaceutical companies in the world.
      They have a large pharmaceutical plant based in Leinster manufacturing lifesaving drugs.
    </p>
    <h2>Project Summary</h2>
    <p>
      This plant contained legacy equipment which was installed circa 15 years ago.
      Multiple deficiencies had become apparent to the client over the years especially considering the
      advances which have been made in the controls industry. The goal was to upgrade the existing equipment,
      a large proportion of which was no longer supported by their manufacturers.
      Controlsoft were also tasked with harmonising the newly installed hardware with the equipment that would remain.
    </p>
    <p>
      As with all Pharma companies, logging of process data is vital in order to retain all necessary licenses to
      manufacture and sell their product. This logging and reporting of data would form a significant part of the project.
      Every aspect of the process, from start to finish, would be audit trailed to comply with the 21CFR part 11 for rules of the Food and Drug Administration.
    </p>
    <h2>Solution</h2>
    <ul>
      <li>To replace legacy PLC units in control areas CIP and AHU.</li>
      <li>To Install and configure an EXSI Server</li>
      <li>To install a new Network to allow communication between 4 different control areas and the main server located in an IT Room.</li>
      <li>To replace existing datalogging equipment with Remote IO and log to a FactoryTalk Historian.s</li>
      <li>To install a new SCADA system to replace existing HMI’s.</li>
      <li>To install a new Modbus communication link to allow for communication between Mitsubishi Q series PLC and Yokogawa PID controllers.</li>
      <li>To install an Intel NUC (7i5BNH) to operate as an interface node translating data from the Mitsubishi PLC’s to the FactoryTalk server</li>
    </ul>
    <h2>Results</h2>
    <ul>
      <li>More automated than before</li>
      <li>Utilising cutting edge equipment that would be supported for the foreseeable future</li>
      <li>More user friendly for operators</li>
      <li>Fully audit trailed</li>
      <li>Fully documented</li>
      <li>Generating automatic batch reports for later analysis</li>
      <li>21 CFR Part 11 Compliant</li>
    </ul>
  </v-card-text>
</template>

<script>
export default {
  name: 'TakedaStudy',
};
</script>

<style scoped>

</style>
