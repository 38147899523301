<template>
  <div>
    <p class="text-center">
      <b class="primary--text">Control</b><b class="accent--text">Soft</b> Automation Systems Ltd are an Irish Software
      Automation Company based in Dundalk, Co. Louth.
      ControlSoft provide turnkey solutions for Process Control requirements and Energy Management for a broad range of
      industries including Data Center, Marine, Food and Beverages and Pharmaceutical.
    </p>
    <v-container>
      <v-row align="stretch">
        <v-col align-self="stretch">
          <p>
            Our highly experienced team of Engineers have a wealth of knowledge spanning over a decade in various
            fields. This experience means we possess cross discipline capabilities when integrating systems from numerous areas
            including networking hardware and database storage with reporting.
          </p>
          <p>
            We are <b class="accent--text">experts</b> in the delivery of PLC and
            SCADA systems to suit your needs. As members of both the Rockwell Automation RCSI (Recognized System Integrators)
            program and the Siemens Solution Partner program for Automation and Drives, we at ControlSoft are recognised
            as reliable, trusted and capable suppliers of creative and innovative solutions for our customers.
          </p>
        </v-col>
        <v-col align-self="stretch">
          <v-divider class="pb-3"></v-divider>

          <v-layout justify-center>
            <v-img :src="top" class="pb-5" max-width="240px" contain />
          </v-layout>
          <v-layout justify-center>
            <v-img :src="middle" class="pb-5" max-width="240px" contain/>
          </v-layout>
          <v-layout justify-center>
            <v-img :src="bottom" class="pb-5" max-width="240px" contain/>
          </v-layout>

          <v-divider class="pb-3"></v-divider>
          <p>
            ControlSoft are honoured to be supported by Ireland’s European Structural and Investments Funds Programmes
            2014-2020, the European Regional Development Fund and Enterprise Ireland. Click <router-link to="/funding">here</router-link> to learn more about how
            their
            contributions have helped to support ControlSoft.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Overview',
  data() {
    return {
      top: require('@/assets/ei_branding/top.png'),
      middle: require('@/assets/ei_branding/middle.png'),
      bottom: require('@/assets/ei_branding/bottom.png'),
      icon: require('@/assets/logo.png'),
    };
  },
};
</script>

<style scoped>

</style>
