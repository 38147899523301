<template>
    <v-container id='charts' class='fade'>
        <v-col cols="12">
            <div>
              <canvas id="RiskChart" width="400" height="180">
              </canvas>
            </div>
        </v-col>
        <v-col cols="12">
          <div>
            <LineChart :chart-data="lineValues" :width="400" :height="180"></LineChart>
          </div>
        </v-col>
    </v-container>
</template>

<script>
import Chart from 'chart.js';
import LineChart from '@/Pages/landing_content/animation_targets/LineChart';

export default {
  name: 'ChartAnimation',
  components: {LineChart},
  data() {
    return {
      lineSpeed: 250,
      lineSamples: 8,
      lineValues: {},
      lineValue: [],
      lineLabel: 'TRG #',
      lineLables: [],
      lineLabelValue: 0,
      interval: null,
      characters: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      count: 0,
      charts: [],
    };
  },
  methods: {
    RiskChartAnimation() {
      const ctx = 'RiskChart';
      this.charts.push(new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Client A', 'Client B', 'Client C', 'Client D', 'Client E', 'Client F'],
          datasets: [{
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
            data: [this.getRandomInt(20), this.getRandomInt(20), this.getRandomInt(20), this.getRandomInt(20), this.getRandomInt(20), this.getRandomInt(20)],
            label: '# client targets on track',
          }],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      }));
    },
    setData() {
      this.lineValue = [];
      this.lineLables = [];

      this.lineValues = {
        labels: this.lineLables,
        datasets: [
          {
            label: 'Target Completion %',
            backgroundColor: 'rgba(110, 198, 216, 0.2)',
            borderColor: 'rgba(101, 105, 107, 0.8)',
            data: this.lineValue,
          },
        ],
      };
    },
    getRandomInt(ceiling) {
      return Math.floor(Math.random() * (ceiling - 5 + 1)) + 5;
    },
    pushData() {
      // this.lineLables.push(`${this.lineLabel}${this.lineLabelValue}`);
      const charset = Array.from(this.characters);
      this.lineLables.push(`${charset[this.lineLabelValue].toUpperCase()}`);
      this.lineValue.push(this.getRandomInt(42));
      this.lineLabelValue = this.lineLabelValue + 1;

      this.lineValues = {
        labels: this.lineLables,
        datasets: [
          {
            label: 'Target Completion %',
            backgroundColor: 'rgba(110, 198, 216, 0.2)',
            borderColor: 'rgba(101, 105, 107, 0.8)',
            data: this.lineValue,
          },
        ],
      };

      if (this.lineValue.length > 5) {
        clearInterval(this.interval);
      }
    },
    clearAndPushData: function() {
      this.lineValue = [];
      this.lineLables = [];

      this.lineLabelValue = 0;
      this.pushData();

      this.interval = setInterval(() => {
        this.pushData();
      }, 900);
    },
  },
  mounted: function() {
    this.setData();
    this.RiskChartAnimation();
    this.pushData();

    this.interval = setInterval(() => {
      this.pushData();
    }, 500);
  },
  watch: {
    resetAnimation: function(value) {
      if (value == true) {
        this.lineValue = [];
        this.interval = setInterval(() => {
          this.pushData();
        }, 1000);
      }
    },
  },
};
</script>

<style>

</style>
