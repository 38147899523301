<template>
  <v-container>
    <v-row justify="center" align="center">
      <AutomationPartnersContainer/>
      <v-row>
        <v-col  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-layout justify-center>
            <v-card elevation="0" max-width="350px" min-width="200px">
              <v-card-title class="align-center justify-center">
                <h3 class="green--text">Energy Management</h3>
                <EnergyHeader></EnergyHeader>
              </v-card-title>
              <v-card-text class="text-center">
                {{energyText}}
              </v-card-text>
            </v-card>
          </v-layout>
        </v-col>
        <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-layout justify-center>
            <v-card elevation="0" max-width="350px" min-width="200px">
              <v-card-title class="align-center justify-center height-adjust" >
                <h3 class="teal--text">Networking</h3>
                <NetworkHeader/>
              </v-card-title>
              <v-card-text class="text-center">
                {{networkText}}
              </v-card-text>
            </v-card>
          </v-layout>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import EnergyHeader from '@/Pages/services_content/EnergyHeader';
import NetworkHeader from '@/Pages/services_content/NetworkHeader';
import AutomationPartnersContainer from '@/Pages/services_content/capabilities/AutomationPartnersContainer';
export default {
  name: 'AutomationCapability',
  components: {NetworkHeader, EnergyHeader, AutomationPartnersContainer},
  metaInfo: {
    title: 'Automation Capabilities',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        description: 'ControlSoft offers automation services built upon decades of experience across a broad range of industries',
      },
      {
        vmid: 'keyword',
        name: 'keyword',
        content: 'Automation, Rockwell, Siemens, Kuka, Industrial Automation, Pharamaceutical automation, Food Automation, Construction Automation, Marine Automation, Mining Automation, Data Centers Automation, Life Sciences Automation',
      },
    ],
    htmlAtts: {
      lang: 'en',
    },
  },
  data() {
    return {
      energyText: 'Over the last 5 years ControlSoft have deployed energy management systems to record the usage of over 750,000 electrical parameters so our customers know exactly how their critical electrical supply is performing. We have installed and configured the full range from standard power meters to high precision power quality meters and provided the reporting systems that complement these solutions.',
      networkText: 'Traditionally the control system network and the office IT network lived very separate lives. This was because the communication protocols that were required by the factory floor were in general not supported by the office ethernet network. In the last decade in particular, we have seen this trend change rapidly. Most modern control system information can be communicated from machine to machine and to the supervisors and managers offices on the same network as the accounts and admin systems.',
    };
  },
};
</script>

<style scoped>
.height-adjust {
  height: 120px;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
