<template>
  <div>
    <v-chip
        class="ma-2 padding-left-null"
        color="accent"
        label
        text-color="white">
      <v-icon left>mdi-label</v-icon>
      IIOT PLATFORM R&D
    </v-chip>
    <p>
      An R&D initiative relating to the development of an Industrial Internet-of-Things Platform has been
      co-funded in this company by the European Regional Development Fund and Enterprise Ireland under the Border,
      Midland and Western Regional Operational Programme 2014-2020.
    </p>

    <h3>Aims</h3>
    <p>
      To establish a homogeneous platform capable of supporting a wide range of site, remote
      and cloud-based solutions that service a variety of industries, capable of being delivered anywhere on the globe.
    </p>

    <h3>Expected Results</h3>
    <p>
      The project is expected to deliver a highly scalable, extensible, secure, robust,
      platform agnostic solution architecture, capable of providing a degree of determinism greater than that
      provided by existing IoT solutions today. When applied in an industrial automation context, this platform is expected to
      have a positive, disruptive impact on the industry, significantly broadening the range of possibilities that
      would otherwise not be possible using a traditional approach.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Platform',
};
</script>

<style scoped>

</style>
