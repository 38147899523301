<template>
  <v-container>
    <v-row class="justify-center">
      <div v-for="(item, i) in images" :key="i">
        <v-col class=" justify-center">
          <div v-if="itemNotSales(item)">
            <v-card elevation="0">
              <v-card-title class="justify-center">
                <div v-if="itemContains(item)">
                  <v-img :src="item" class="pa-2 visualComponents" max-width="220px" max-height="120px" contain/>
                </div>
                <div v-else-if="itemAlign(item)">
                  <v-img :src="item" class="pa-2 align-logo" max-width="250px" max-height="175px" contain/>
                </div>
                <div v-else>
                  <v-img :src="item" class="pa-2" max-width="250px" max-height="175px" contain/>
                </div>
              </v-card-title>
            </v-card>
          </div>
        </v-col>
      </div>
    </v-row>
    <!-- <v-row class="justify-center">
      <v-col class="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-xs-12">
        <v-card elevation="0">
          <v-card-title class="justify-center">
            <v-img :src="phoenix" class="pa-2 " max-width="250px" max-height="150px" contain/>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col class="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-xs-12">
        <v-card elevation="0">
          <v-card-title class="justify-center">
            <v-img :src="rital" class="pa-2" max-width="250px" max-height="125px" contain/>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  name: 'PartnerList',
  data() {
    return {
      images: [],
      rital: require('@/assets/partners/Ritual.jpg'),
      phoenix: require('@/assets/partners/PheonixContact.jpg'),
    };
  },
  mounted() {
    this.importAll(require.context('@/assets/partners/', true, /\.jpg$/));
    this.images = this.images.map((it) => it.pathLong);
  },
  methods: {
    importAll(r) {
      r.keys().forEach((key) => (this.images.push({pathLong: r(key), pathShort: key})));
    },
    itemContains(value) {
      if (value.includes('VisualComponentsLogo')) {
        return true;
      }
      return false;
    },
    itemAlign(value) {
      if (value.includes('solidworks')) {
        return true;
      }
      return false;
    },
    itemNotSales(value) {
      const sales = [this.rital, this.phoenix];
      if (sales.includes(value)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.visualComponents {
  margin-top: 35px;
}

.align-logo {
  margin-top: 20px;
}
</style>
