<template>
  <Page class="eula">
    <v-card>
      <v-card-text>
        <h2>End-User License Agreement (EULA) of ControlSoft Core Framework</h2>
        <p>This End-User License Agreement ("EULA") is a legal agreement between you and ControlSoft Automation Systems Limited.</p>

        <p>This EULA agreement governs your acquisition and use of our ControlSoft Core Framework software ("Software") directly from ControlSoft Automation Systems Limited or indirectly through a ControlSoft Automation Systems Limited authorized reseller or distributor (a "Reseller").</p>

        <p>Please read this EULA agreement carefully before completing the installation process and using the ControlSoft Core Framework software. It provides a license to use the ControlSoft Core Framework software and contains warranty information and liability disclaimers.</p>

        <p>If you register for a free trial of the ControlSoft Core Framework software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the ControlSoft Core Framework software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</p>

        <p>If you are entering into this EULA agreement on behalf of a company
          or other legal entity, you represent that you have the authority to bind
          such entity and its affiliates to these terms and conditions. If you do
          not have such authority or if you do not agree with the terms and
          conditions of this EULA agreement, do not install or use the Software,
          and you must not accept this EULA agreement.</p>

        <p>This EULA agreement shall apply only to the Software supplied by ControlSoft Automation Systems Limited herewith regardless of whether other software is referred to or described herein. The terms also apply to any ControlSoft Automation Systems Limited
          updates, supplements, Internet-based services, and support services for
          the Software, unless other terms accompany those items on delivery. If
          so, those terms apply.

        </p>

        <h3>Definitions</h3>

        <p>"<strong>License</strong>" shall mean the terms and conditions for use, reproduction, and distribution as defined by this document.</p>

        <p>"<strong>Legal Entity</strong>" shall mean the union of the acting entity and
          all other entities that control, are controlled by, or are under common
          control with that entity. For the purposes of this definition, "control"
          means (i) the power, direct or indirect, to cause the direction or
          management of such entity, whether by contract or otherwise, or (ii)
          ownership of fifty percent (50%) or more of the outstanding shares, or
          (iii) beneficial ownership of such entity.</p>

        <p>"<strong>You</strong>" (or "<strong>Your</strong>") shall mean an individual or Legal Entity exercising permissions granted by this License.

        </p>

        <h3>License Grant</h3>

        <p>ControlSoft Automation Systems Limited hereby grants you a non-transferable, non-exclusive license to use the ControlSoft Core Framework software on your devices in accordance with the terms of this EULA agreement.</p>

        <p>You are permitted to load the ControlSoft Core Framework
          software (for example a PC, server, laptop, mobile or tablet) under
          your control. You are responsible for ensuring your device meets the
          minimum requirements of the ControlSoft Core Framework software.</p>

        <b>You are not permitted to:</b>
        <ul><li>Edit, alter, modify, adapt, translate or otherwise change the whole  or  any part of the Software nor permit the whole or any part of the   Software to be combined with or become incorporated in any other   software without the express conscent of ControlSoft Automation Systems  Limited, nor decompile, disassemble or reverse engineer the Software or  attempt to do any such things.</li><li>Reproduce, copy, distribute, resell or otherwise use the Software for  any commercial purpose without the express conscent of ControlSoft  Automation Systems Limited.</li><li>Allow any third party to use the Software on behalf of or for the benefit of any third party.</li><li>Use the Software in any way which breaches any applicable local, national or international law.</li><li>Use the Software for any purpose that ControlSoft Automation Systems Limited considers is a breach of this EULA agreement.</li></ul>

        <h3>Intellectual Property and Ownership</h3>
        <p>ControlSoft Automation Systems Limited
          shall at all times retain ownership of the Software as originally
          downloaded by you and all subsequent downloads of the Software by you.
          The Software (and the copyright, and other intellectual property rights
          of whatever nature in the Software, including any modifications made
          thereto) are and shall remain the property of ControlSoft Automation Systems Limited.</p>
        <p>ControlSoft Automation Systems Limited reserves the right to grant licenses to use the Software to third parties.</p>
        <h3>Termination</h3>
        <p>It will also terminate immediately if you fail to comply with any
          term of this EULA agreement. Upon such termination, the licenses granted
          by this EULA agreement will immediately terminate and you agree to stop
          all access and use of the Software. The provisions that by their nature
          continue and survive will survive any termination of this EULA
          agreement.</p>
        <p>This EULA agreement is effective from the date you first use the
          Software and shall continue until terminated. You may terminate it at
          any time upon written notice to ControlSoft Automation Systems Limited.</p>
        <h3>Governing Law</h3>
        <p>This EULA agreement, and any dispute arising out of or in connection
          with this EULA agreement, shall be governed by and construed in
          accordance with the laws of the Republic of Ireland.</p>
      </v-card-text>
    </v-card>

  </Page>
</template>

<script>
import Page from '@/templates/Page/Page';
export default {
  name: 'EULA',
  metaInfo: {
    title: 'End-user License Agreement ',
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {Page},
};
</script>

<style scoped>
b {
  padding-bottom: 3px;
}
ul {
  padding-bottom: 12px;
}
li {
  padding-top:0;
  padding-bottom: 3px;
}

h2 {
  padding-bottom: 15px;
}

p, h1, h3, h4, h5, h6{
  padding-bottom: 3px;
}
</style>
