<template>
  <Page name="Home">
    <v-row justify="center" align="center">
      <v-col class="pa-0">
        <Jumbotron/>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12">
        <LandingAnimation height="500"></LandingAnimation>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
        <Banner text="Our Clients"/>
    </v-row>
    <v-row>
      <v-col>
        <ClientList/>
      </v-col>
    </v-row>
    <v-row>
      <Banner text="Our Partners"/>
    </v-row>
    <v-row>
      <v-col>
        <PartnerList/>
      </v-col>
    </v-row>
    <div>
      <v-snackbar :value="snackbar" right timeout="15000">
        <v-layout align-center justify-center>
          <v-btn class="button accent" width="100%" large @click="$router.push('/careers')">
            Now Hiring
          </v-btn>
        </v-layout>
        <template v-slot:action="{ close }">
          <v-btn
            color="accent"
            text
            v-bind="close"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </Page>
</template>

<script>
import Page from '../templates/Page/Page';
import LandingAnimation from '@/Pages/landing_content/LandingAnimation';
import Jumbotron from '@/components/Jumbotron';
import ClientList from '@/Pages/landing_content/ClientList';
import Banner from '@/components/Banner';
import PartnerList from '@/Pages/landing_content/PartnerList';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Home',
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {
    PartnerList,
    Banner,
    ClientList,
    Jumbotron,
    LandingAnimation,
    Page,
  },
  data: () => {
    return {
      snackbar: true,
    };
  },
};
</script>

<style scoped>

</style>
