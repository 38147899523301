<template>
  <IndustryContent text="Mining"
                   icon="pickaxe"
                   banner-text="Mining & Materials - Client Case Study"
                   icon-color="purple">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <h2>Client</h2>
    <p>
      Kingspan is the world leading manufacturer of insulation boards in the building industry. They are located
      globally with 100 locations across the world. They are one of the most well-known and trusted manufactures in this
      industry. This specific project was based in Arkansas.
    </p>
    <h2>Project Summary</h2>
    <p>
      The process used by Kingspan involves the mixing of multiple chemicals to a precise ratio and applying the mix
      onto metal sheets which form the outer wall of the panel. The chemical mix produces a foam which when cured
      becomes hard and has excellent
    </p>
    <p>
      The process uses one highly explosive chemical which needs very careful handling. Because of this, a separate
      safety system was added in order to detect the presence of this chemical. Once detected, the PLC would shunt
      incoming power from external panels in order to make the whole environment safe.
    </p>
    <h2>Solution</h2>
    <p>
      The unique aspect of this particular project was the hardware and software used. This process had never previously
      used an Allen Bradley control system solution. This required a careful choice of hardware coupled with new custom
      PLC code to carry out the process. This new system also had to interface with several other existing PLCs and
      hardware in order to facilitate smooth operation of the entire production line.
    </p>
    <p>
      The safety part of the project required rigorous testing to meet Kingspan safety standard. The system had to
      perform correctly every time in the event it was required.
      A SCADA system was used to control, monitor and log key data for the process.
    </p>
    <h2>Results</h2>
    <p>
      The system did not take long to commission due to the tireless effort made before visiting site. An experienced
      team onsite coupled with the very modular system Controlsoft employed resulted in a project that was both simple
      to commission and flexible enough to alter as required.
    </p>
    <p>
      The client was delighted with the commissioning which took place alongside active production. The operators were
      very pleased with the ease of use of the SCADA system and enjoyed having all their key parameters visible in a
      human-friendly way.
    </p>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';

export default {
  name: 'MiningServed',
  components: {IndustryContent},
  data() {
    return {
      img: require('@/assets/industries_served/mining.jpg'),
    };
  },
};
</script>

<style scoped>

</style>
