import { render, staticRenderFns } from "./PackagingServed.vue?vue&type=template&id=901c51a2&scoped=true&"
import script from "./PackagingServed.vue?vue&type=script&lang=js&"
export * from "./PackagingServed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "901c51a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VImg,VLayout})
