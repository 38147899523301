<template>
  <IndustryContent banner-text="Packaging - Kerry Foods Client Case study"
                   text="Packaging"
                   icon="mailbox-up-outline"
                   icon-color="brown">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <h2>Client</h2>
    <p>
      Kerry Foods are a Dairy Produce Packers based in Ireland.
      This job was on the retail palletising line, were the product is packing slices of cheese into boxes. The machines
      are gantry robotic palletisers. There are 3 identical machines.
    </p>
    <h2>Project Summary</h2>
    <p>
      The machines were built in 1997. The control hardware, now at 20 years of age was beginning to show signs of
      failure. The Human Machine Interface(HMI) was a Windows 95 device which was obsolete and uneconomical to maintain.
      The system was becoming unreliable and unsafe, downtime was increasing and operators were being put at risk.
      Controlsoft were asked to supply a complete upgrade solution which could be adapted to the clients future
      products.
    </p>
    <h2>Solution</h2>
    <p>
      Controlsoft designed, programmed, and installed a complete control solution. The PLC, I/O, drives and HMI were all
      replaced with the latest Allen Bradley hardware.
      The control software was rewritten from the ground up to comply with the latest standards. Recipes were created
      for each product and packing style. After simulation in the workshop, the system was installed and tested to
      ensure it performed exactly as required.
    </p>
    <h2>Results</h2>
    <p>
      The client was completely satisfied with the upgrade. One machine was upgraded at a time to minimise disruption to the packing line.
      Operators were much happier with the machines performance and felt safer when working beside the line. Downtime was reduced to practically zero.
      A critical spares list was supplied to the client to ensure availability of parts in the case of failure.
    </p>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';

export default {
  name: 'PackagingServed',
  components: {IndustryContent},
  data() {
    return {
      img: require('@/assets/industries_served/packaging.jpg'),
    };
  },
};
</script>

<style scoped>

</style>
