<template>
  <v-container style="width: 50%">
    <v-row align="center" justify="center">
      <PharmaServed/>
      <DataServed/>
      <FoodServed/>
      <ConstructionServed/>
      <MiningServed/>
      <PackagingServed/>
      <LifeScienceServed/>
      <NutritionServed/>
      <BioSciencesServed/>
      <MarineServed/>
    </v-row>
  </v-container>
</template>

<script>
import PharmaServed from '@/Pages/services_content/industries_served/PharmaServed';
import DataServed from '@/Pages/services_content/industries_served/DataServed';
import FoodServed from '@/Pages/services_content/industries_served/FoodServed';
import ConstructionServed from '@/Pages/services_content/industries_served/ConstructionServed';
import MiningServed from '@/Pages/services_content/industries_served/MiningServed';
import PackagingServed from '@/Pages/services_content/industries_served/PackagingServed';
import LifeScienceServed from '@/Pages/services_content/industries_served/LifeScienceServed';
import NutritionServed from '@/Pages/services_content/industries_served/NutritionServed';
import BioSciencesServed from '@/Pages/services_content/industries_served/BioSciencesServed';
import MarineServed from '@/Pages/services_content/industries_served/MarineServed';

export default {
  name: 'IndustriesServed',
  components: {
    MarineServed,
    BioSciencesServed,
    NutritionServed,
    LifeScienceServed,
    PackagingServed, MiningServed, ConstructionServed, FoodServed, DataServed, PharmaServed},
  data() {
    return {
      industries: [
        {
          name: 'Pharmaceutical',
          icon: 'needle',
          color: 'blue',
        },
        {
          name: 'Data Centers',
          icon: 'database',
          color: 'red',
        },
        {
          name: 'Food',
          icon: 'food',
          color: 'green',
        },
        {
          name: 'Construction ',
          icon: 'account-hard-hat',
          color: 'orange',
        },
        {
          name: 'Mining & Minerals',
          icon: 'pickaxe',
          color: 'purple',
        },
        {
          name: 'Packaging',
          icon: 'mailbox-up-outline',
          color: 'brown',
        },
        {
          name: 'Life Sciences',
          icon: 'heart-plus',
          color: 'pink',
        },
        {
          name: 'Nutrition',
          icon: 'molecule',
          color: 'cyan',
        },
        {
          name: 'Bio Sciences',
          icon: 'flask-outline',
          color: 'lime',
        },
        {
          name: 'Marine',
          icon: 'ferry',
          color: 'teal',
        },
      ],
    };
  },
};
</script>

<style scoped>
* {
  word-break: normal !important;
}
</style>
