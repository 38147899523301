<template>
  <v-container>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="2">
        <v-layout align-center justify-center>
          <v-icon>
            mdi-monitor
          </v-icon>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-progress-linear color="green" reverse indeterminate buffer-value="0"/>
      </v-col>
      <v-col cols="2" align-self="center">
        <v-layout align-center justify-center>
          <v-icon>
            mdi-factory
          </v-icon>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-progress-linear color="blue" indeterminate buffer-value="0"/>
      </v-col>
      <v-col cols="2">
        <v-layout align-center justify-center>
          <v-icon>
            mdi-monitor-dashboard
          </v-icon>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NetworkHeader',
};
</script>

<style scoped>

</style>
