<template>
  <v-container>
    <v-row justify="center" align="center" >
      <v-col align-self="center" class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 align-center justify-center">
        <v-card elevation="0">
          <v-card-title class="justify-center align-center">
            <v-img :src="csharpimg"
              contain
              max-height="100"
              max-width="100"
              id="csharp"/>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col align-self="center" class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 align-center justify-center">
        <DotNetAnimation/>
      </v-col>
      <v-col align-self="center" class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 align-center justify-center">
        <v-card elevation="0">
          <v-card-title class="justify-center align-center">
            <v-img :src="blazorimg"
              contain
              max-height="100"
              max-width="100"/>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import DotNetAnimation from '@/Pages/landing_content/animation_targets/DotNetAnimation';

export default {
  name: 'MarineServedTechnology',
  components: {DotNetAnimation},
  data() {
    return {
      csharpimg: require('@/assets/technology/csharp.png'),
      blazorimg: require('@/assets/technology/blazor.png'),
    };
  },
  methods: {
    animateDotNetSVG() {
      const dotNetInitialDelay = 300;
      const dotNetInitialDuration = 3500;

      const dotNetMiddleDelay = 150;
      const dotNetMiddleDuration = 2000;

      const dotNetEndingDelay = 350;
      const dotNetEndingDuration = 1250;

      anime.timeline({
        loop: true,
      })
          .add({
            targets: '.dotnet-svg path',
            strokeDashoffset: [anime.setDashoffset, 0],
            direction: 'normal',
            translateX: [-115, 0],
            fill: ['rgba(0,0,0,0)', '#5c2d91'],
            easing: 'easeInBounce',
            delay: function(el, i) {
              return i * dotNetInitialDelay;
            },
            duration: dotNetInitialDuration,
          })
          .add({
            targets: '.dotnet-svg path',
            strokeDashoffset: [anime.setDashoffset, 0],
            direction: 'normal',
            fill: ['#5c2d91', '#9b4f96', '#5c2d91'],
            translateX: [0, 10, 0],
            easing: 'easeOutExpo',
            delay: function(el, i) {
              return i * dotNetMiddleDelay;
            },
            duration: dotNetMiddleDuration,
          })
          .add({
            targets: '.dotnet-svg path',
            strokeDashoffset: [anime.setDashoffset, 0],
            direction: 'normal',
            translateX: [0, -150],
            fill: ['#5c2d91', 'rgba(0,0,0,0)'],
            easing: 'easeOutExpo',
            delay: function(el, i) {
              return i * dotNetEndingDelay;
            },
            duration: dotNetEndingDuration,
          });
    },
  },
  mounted() {
    this.animateDotNetSVG();
  },
};
</script>

<style scoped>

</style>
