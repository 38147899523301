<template>
  <v-card class="text-center d-flex flex-column">
    <Stylevider/>
    <h2>Contact Us</h2>
    <v-card-subtitle><b>We're available by phone and email</b></v-card-subtitle>
    <v-card-text class="d-flex flex-column">
      <div>
        <v-row>
          <v-col class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 align-center justify-center">
            <v-btn width="100%" elevation="3" color="accent" @click="openMailToSales()">
              <v-icon small>mdi-email</v-icon>
              <div>Email</div>
            </v-btn>
          </v-col>
          <v-col class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-left">
            For a <b>quotation</b> <a href="mailto:admin@controlsoft.ie">sales@controlsoft.ie</a><br>
            For <b>after sales support</b> <a href="mailto:support@controlsoft.ie">support@controlsoft.ie</a><br>
            For <b>job applications</b> <a href="mailto:careers@controlsoft.ie">careers@controlsoft.ie</a>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 align-center justify-center">
            <v-btn width="100%" elevation="3" color="secondary" @click="openPhone()">
              <v-icon small>mdi-phone</v-icon>
              Phone
            </v-btn>
          </v-col>
          <v-col class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-left">
            <div class="justify-center phone-text">
              <a href="tel://+353429326060" >+353 42 932 6060</a><br><br>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="address">
        <v-row>
          <v-col class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 align-center justify-center">
            <v-btn width="100%" elevation="3" color="accent" @click="openGoogleMaps()">
              <v-icon>mdi-map-marker</v-icon>
              Address
            </v-btn>
          </v-col>
          <v-col class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-left">
            23 North Link Business Park, Coes Rd,<br> Marshes Lower, Dundalk,<br> Co. Louth, A91 XN9D
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Stylevider from '@/components/Stylevider';
export default {
  name: 'ContactCard',
  components: {Stylevider},
  methods: {
    openGoogleMaps() {
      window.open('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2344.9966191288772!2d-6.381285783837378!3d54.00283558012186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860cc06fee1b07d%3A0xab652bce2bf37118!2sControlSoft%20Automation%20Systems!5e0!3m2!1sen!2sie!4v1599466382989!5m2!1sen!2sie');
    },
    openPhone() {
      window.location.href = 'tel://+353429326060';
    },
    openMailToSales() {
      window.location.href = 'mailto:admin@controlsoft.ie';
    },
  },
};
</script>

<style scoped>
.address {
  margin-top: 8px;
}
.phone-text {
  margin-top: 8px;
}
</style>
