<template>
  <v-container>
    <v-row>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-text class="text-left">
              {{kukaText}}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-col>
      <v-col class="col-xl-6 col-lg-5 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0" href="https://www.kuka.com/" target="_blank">
            <v-card-title class="align-center justify-center">
              <v-img :src="kuka" contain max-width="310px" class="kuka" />
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KukaContent',
  data() {
    return {
      kuka: require('@/assets/capabilities/kuka.png'),
      kukaText: 'ControlSoft offers KUKA Industrial Robots in a wide range of versions with various payload capacities and reaches. Our spectrum of products also includes the appropriate robot peripheral equipment – from linear units to end effectors. Combined with cutting-edge software and innovative controllers, we develop individual solutions for your manufacturing processes together with you.',
    };
  },
};
</script>

<style scoped>
.kuka {
  margin-left: 25px;
  margin-top: 25px;
}

@media (max-width: 960px) {
  .row {
    flex-direction: column-reverse;
  }
}
</style>
