<template>
  <v-container v-if="shouldDisplayDesktopView()">
    <v-row no-gutters>
      <v-col cols="4">
        <v-carousel class="text-center visuals"
          cycle
          @change="gampVmodelCheck(currentIndex)"
          eager
          light
          :show-arrows="false"
          :show-arrows-on-hover="false"
          height="100%"
          continuous
          hide-delimiters
          hide-delimiter-background
          v-model="currentIndex">
          <v-carousel-item>
            <ScadaAnimation/>
          </v-carousel-item>
          <v-carousel-item>
            <ChartAnimation ref="charts"/>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="4">
        <LogoAnimation height="500"/>
      </v-col>
      <v-col cols="4">
        <v-carousel class="text-center industrial"
          cycle
          eager
          light
          :show-arrows="false"
          :show-arrows-on-hover="false"
          height="100%"
          continuous
          hide-delimiters
          hide-delimiter-background>
          <v-carousel-item>
            <FactoryAnimation/>
          </v-carousel-item>
          <v-carousel-item>
            <GampVmodelAnimation :height="500"/>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row class="text-center" no-gutters>
      <v-col cols="4">
        <v-carousel class="text-center visuals-text"
            cycle
            eager
            light
            :show-arrows="false"
            :show-arrows-on-hover="false"
            height="100%"
            continuous
            hide-delimiters
            hide-delimiter-background>
          <v-carousel-item>
            <h1 class="text-no-wrap">Modern Interfaces</h1>
          </v-carousel-item>
          <v-carousel-item>
            <h1 class="text-no-wrap">Advanced Visualisation</h1>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="4">
        <ControlSoftSVG/>
      </v-col>
      <v-col cols="4">
        <v-carousel class="text-center industrial-text"
            cycle
            light
            eager
            :show-arrows="false"
            :show-arrows-on-hover="false"
            height="100%"
            continuous
            hide-delimiters
            hide-delimiter-background>
          <v-carousel-item>
            <h1 class="text-no-wrap">Industrial Automation</h1>
          </v-carousel-item>
          <v-carousel-item>
            <h1 class="text-no-wrap">Proven Methodologies</h1>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
  <v-carousel v-else
              class="text-center"
              cycle
              @change="gampVmodelCheck(currentIndex)"
              light
              :show-arrows="false"
              :show-arrows-on-hover="false"
              :interval="6000"
              height="500"
              continuous
              hide-delimiters
              hide-delimiter-background
              v-model="currentIndex">
    <v-carousel-item color="transparent">
      <LogoAnimation/>
      <ControlSoftSVG/>
    </v-carousel-item>
    <v-carousel-item>
      <GampVmodelAnimation/>
      <h1 class="text-no-wrap">Proven Methodologies</h1>
    </v-carousel-item>
    <v-carousel-item eager>
      <FactoryAnimation/>
      <h1 class="text-no-wrap">Industrial Automation</h1>
    </v-carousel-item>
    <v-carousel-item eager>
      <ScadaAnimation/>
      <h1 class="text-no-wrap">Modern Interfaces</h1>
    </v-carousel-item>
    <v-carousel-item>
      <ChartAnimation ref="mobileCharts"/>
      <h1 class="text-no-wrap">Advanced Visualisation</h1>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import ControlSoftSVG from '@/Pages/landing_content/ControlSoftSVG';
import LogoAnimation from '@/Pages/landing_content/animation_targets/LogoAnimation';
import ScadaAnimation from '@/Pages/landing_content/animation_targets/ScadaAnimation';
import FactoryAnimation from '@/Pages/landing_content/animation_targets/FactoryAnimation';
import GampVmodelAnimation from '@/Pages/landing_content/animation_targets/GampVmodelAnimation';
import ChartAnimation from '@/Pages/landing_content/animation_targets/ChartAnimation';

export default {
  name: 'LandingAnimation',
  components: {ScadaAnimation, FactoryAnimation, LogoAnimation, ControlSoftSVG, GampVmodelAnimation, ChartAnimation},
  data() {
    return {
      carouselInterval: null,
      chartMobileIndex: 4,
      chartsRendered: false,
      currentIndex: 0,
      desktop: null,
      factoryMobileIndex: 2,
      gampindex: 1,
      gampShowing: false,
      scadaMobileIndex: 3,
    };
  },
  methods: {
    shouldDisplayDesktopView() {
      this.desktop = this.$vuetify.breakpoint.name !== 'sm' && this.$vuetify.breakpoint.name !== 'xs';
      return this.desktop;
    },
    animateLogo() {
      const logoLoopingDelay = 3500;
      const logoLoopingDuration = 2500;
      const logoFadeInDuration = 4000;

      const landingAnimationDelay = 1700;
      const landingAnimationDuration = 4300;

      const secondAnimationDelay = 1700;
      const secondAnimationDuration = 4300;

      anime({
        targets: '.logo-svg',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: logoLoopingDelay,
        duration: logoLoopingDuration,
        loop: true,
      });

      anime({
        targets: '.logo-svg',
        opacity: ['0%', '100%'],
        duration: logoFadeInDuration,
        easing: 'easeInSine',
      });

      anime({
        targets: '.landing-animation',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: landingAnimationDelay,
        duration: landingAnimationDuration,
        loop: true,
      });

      anime({
        targets: '.second-svg',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: secondAnimationDelay,
        duration: secondAnimationDuration,
        loop: true,
      });
    },
    desktopAnimation() {
      const timelineDuration = 4000;
      const timelineLandingAnimationDelayOffset = 250;
      const timelineSecondDelayOffset = 250;
      const timelineSecondAbsoluteOffset = 1500;

      const timeline = anime.timeline({
        easing: 'easeInOutSine',
        duration: timelineDuration,
      });

      timeline.add({
        targets: '.landing-animation',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay(el, i) {
          return i * timelineLandingAnimationDelayOffset;
        },
        direction: 'alternate',
      });

      timeline.add({
        targets: '.second-svg',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay(el, i) {
          return i * timelineSecondDelayOffset;
        },
        direction: 'alternate',
      }, timelineSecondAbsoluteOffset);

      this.animateLogo();
    },
    mobileAnimation() {
      const mobileAnimationDelay = 2500;
      const mobileAnimationDuration = 3500;

      anime({
        targets: ['.logo-svg', 'vmodel g'],
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: mobileAnimationDelay,
        duration: mobileAnimationDuration,
        loop: true,
      });
    },
    gampAnimation() {
      const gampAnimationDelay = 1200;
      const gampAnimationDuration = 2000;

      anime({
        targets: ['.circle-one', '.circle-two', '.circle-three', '.circle-four'],
        strokeDashoffset: [anime.setDashoffset, 0],
        fill: ['rgba(0,0,0,0)', '#65696b'],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: gampAnimationDelay,
        duration: gampAnimationDuration,
        loop: true,
      });

      anime({
        targets: '.circle-one',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        translateX: [25, -25],
        easing: 'easeInOutSine',
        delay: gampAnimationDelay,
        duration: gampAnimationDuration,
        loop: true,
      });

      anime({
        targets: '.circle-two',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        translateX: [50, -50],
        easing: 'easeInOutSine',
        delay: gampAnimationDelay,
        duration: gampAnimationDuration,
        loop: true,
      });

      anime({
        targets: '.circle-three',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        translateX: [80, -80],
        easing: 'easeInOutSine',
        delay: gampAnimationDelay,
        duration: gampAnimationDuration,
        loop: true,
      });

      anime({
        targets: '.circle-four',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        translateX: [110, -110],
        easing: 'easeInOutSine',
        delay: gampAnimationDelay,
        duration: gampAnimationDuration,
        loop: true,
      });
    },
    sacadaMobileAnimation() {
      const animationDelay = 1700;
      const animationDuration = 4300;

      anime({
        targets: '.landing-animation',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: animationDelay,
        duration: animationDuration,
        loop: true,
      });
    },
    factoryMobileAnimation() {
      const animationDelay = 1700;
      const animationDuration = 4300;

      anime({
        targets: '.second-svg',
        strokeDashoffset: [anime.setDashoffset, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: animationDelay,
        duration: animationDuration,
        loop: true,
      });
    },
    gampToggleFade() {
      const vmodelCheck = document.getElementById('vmodel');
      if (vmodelCheck.classList.contains('fade')) {
        vmodelCheck.classList.toggle('fade');
      }
    },
    gampVmodelCheck(index) {
      if (index == this.gampindex) {
        if (!this.gampShowing) {
          setTimeout(() => {
            this.gampAnimation();
            this.gampToggleFade();
            this.gampShowing = true;
          }, 500);
        } else if (this.gampShowing == true && this.desktop) {
          this.gampAnimation();
          this.$refs.charts.clearAndPushData();
          this.gampToggleFade();
        } else if (this.gampShowing == true && !this.desktop) {
          this.gampAnimation();
          this.gampToggleFade();
        }
      } else if (index == this.chartMobileIndex && !this.desktop) {
        if (this.chartsRendered) {
          this.$refs.mobileCharts.clearAndPushData();
        } else {
          this.chartsRendered = true;
        }
      } else if (index == this.scadaMobileIndex && !this.desktop) {
        this.sacadaMobileAnimation();
      } else if (index == this.factoryMobileIndex && !this.desktop) {
        this.factoryMobileAnimation();
      }
    },
    advanceCarousels() {
      const visuals = document.querySelector('visuals');
      const visualsText = document.querySelector('visuals-text');
      const industrial = document.querySelector('industrial');
      const industrialText = document.querySelector('industrial-text');

      this.carouselInterval = setInterval(() => {
        visuals.next();
        visualsText.next();
        industrial.next();
        industrialText.next();
      }, 6000);
    },
  },
  mounted() {
    if (this.shouldDisplayDesktopView()) {
      this.desktopAnimation();
    } else {
      this.mobileAnimation();
    }
  },
};
</script>

<style scoped>
  .title-text {
    font-family: 'BellGothicStd', Arial, sans-serif;
    font-weight: bold;
  }

  #vmodel {
    opacity: 1;
    transition: opacity 2.5s;
  }

  #vmodel.fade {
    opacity: 0;
  }

</style>
