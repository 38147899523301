<template>
  <IndustryContent banner-text="Nutrition - Abbott case study"
                   text="Nutrition"
                   icon="molecule"
                   icon-color="cyan">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <h2>Client</h2>
    <p>
      Abbott create breakthrough products in: diagnostics, medical devices, nutrition and branded generic
      pharmaceuticals.
      In Abbott’s nutrition division, they produce infant nutritional products.
    </p>
    <h2>Project Summary</h2>
    <p>
      Abbott are continuously improving the packing lines on site to improve energy efficiency and also improved
      OEE(Overall equipment effectiveness).
      We provide support to the onsite engineers and we then implement changes to improve the lines.
      Abbott also introduced new can designs for different markets.
      When new cans are introduced, line control changes are implemented to ensure the quality of the can introduced to
      the market is of the highest quality.
    </p>
    <h2>Solution</h2>
    <p>
      We Provide 24/7 support for the 4 packing areas and the utilities for the site including water treatment and
      effluent treatment.
      This includes support over the phone and also site visits to solve the issues.
      Abbott mainly uses Siemens PLCs on site, including S5, Step7 and TIA Portal. They also have Allen Bradley on site.
    </p>
    <h2>Results</h2>
    <p>
      We are also involved in projects on site to implement new systems. One example of this is the new Bag Off System
      for their bulk packaging of powder.
      This project involved filling powder into bags to a weight of 550kg.
      The implementation of this system has now doubled the output when compared to the old system.
    </p>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';

export default {
  name: 'NutritionServed',
  components: {IndustryContent},
  data() {
    return {
      img: require('@/assets/industries_served/nutrition.jpg'),
    };
  },
};
</script>

<style scoped>

</style>
