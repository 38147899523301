<template>
  <Page name="Contact">
    <v-row>
      <Banner text="Get in touch"/>
    </v-row>
    <v-row align="stretch" justify="center">
      <v-col class="d-flex flex-column"
             style="flex-direction:column"
             xs="12"
             sm="12"
             md="4">
        <ContactCard class="flex-grow-1"/>
      </v-col>
      <v-col class="d-flex flex-column"
             style="flex-direction:column"
             xs="12"
             sm="12"
             md="4">
        <OpeningHours class="flex-grow-1"/>
      </v-col>
    </v-row>
    <v-row>
      <Banner text="Our Location"/>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <GoogleMap/>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from '../templates/Page/Page';
import GoogleMap from '@/components/GoogleMap';
import ContactCard from '@/Pages/contact_content/ContactCard';
import OpeningHours from '@/Pages/contact_content/OpeningHours';
import Banner from '@/components/Banner';

export default {
  name: 'ContactUs',
  metaInfo: {
    title: 'Contact us',
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {
    Banner,
    OpeningHours,
    ContactCard,
    GoogleMap,
    Page,
  },
};
</script>

<style scoped>

</style>
