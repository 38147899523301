<template>
  <v-container>
    <v-row>
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-text class="text-left">
              {{raspberryPiText}}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-col>
      <v-col class="col-xl-6 col-lg-5 col-md-6 col-sm-12 col-xs-12">
        <v-layout justify-center>
          <v-card elevation="0"  href="https://www.raspberrypi.com/for-industry/" target="_blank">
            <v-card-title class="align-center justify-center">
              <v-img :src="raspberryPi" contain max-width="310px" class="raspberryPi" />
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RaspberryPiContent',
  data() {
    return {
      raspberryPi: require('@/assets/capabilities/raspberry-pi.png'),
      raspberryPiText: 'ControlSoft offer specialist knowledge and proven expertise in designing and manufacturing powerful, flexible and robust solutions based on the Raspberry Pi - Certified for Industry product range.  As a Raspberry PI Authorized Design Partner, ControlSoft receive direct support from the experts at Raspberry Pi, securing our customers in the knowledge that their solutions have been engineered to highest standards for industry.',
    };
  },
};
</script>

<style scoped>
.raspberryPi {
  margin-left: 25px;
  margin-top: 25px;
}

@media (max-width: 960px) {
  .row {
    flex-direction: column-reverse;
  }
}
</style>
