<template>
  <div v-if="this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'">
    <v-layout justify-center align-center>
      <v-carousel cycle hide-delimiter-background hide-delimiters show-arrows-on-hover style="width: 250px; height: 250px" >
        <v-carousel-item v-for="(slide, i) in originalCopy" :key="i" style="width: 250px">
          <v-layout justify-center align-center>
          <v-img :src="slide"/>
          </v-layout>
        </v-carousel-item>
      </v-carousel>
    </v-layout>
  </div>
  <div v-else>
    <v-container>
      <v-row v-for="(set, i) in images" :key="i" justify="center" no-gutters>
        <v-col v-for="(image, j) in set" :key="j"
               class="pa-2 col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-6 target-image">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 5 : 2" class="hoverImg" ripple tile>
              <v-card-text>
                <v-img :src="image.src" :width="image.width + '%'" position="center"/>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'ClientList',
  data() {
    return {
      images: [],
      originalCopy: [],
      width: '30',
    };
  },
  mounted() {
    this.importAll(require.context('@/assets/clients/', true, /\.jpg$/));
    this.originalCopy = this.images.map((it) => it.pathLong);

    // If we're working on a mobile browser we definitely do not want to bother triggering the animation
    // It is expensive and it will fail.
    if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
      return;
    }

    this.images = this.images.map((it) => ({
      src: it.pathLong,
      width: 30,
    }));
    this.images = this.images.chunk(12);
    this.animate();
  },
  methods: {
    importAll(r) {
      r.keys().forEach((key) => (this.images.push({pathLong: r(key), pathShort: key})));
    },
    animate() {
      let from = 'last';
      const clientsAbsoluteOffset = 1000;

      for (let i = 0; i < this.images.length; i++) {
        setTimeout(() => {
          if (from === 'last') {
            from = 'first';
          } else {
            from = 'last';
          }
          anime({
            targets: this.images[i],
            width: 100,
            easing: 'easeInQuad',
            delay: anime.stagger(80, {from}),
          });
        }, clientsAbsoluteOffset * i);
      }
    },
  },
};
</script>

<style scoped>
  .hoverImg:hover {
    transform: scale(1.1);
  }
</style>
