<template>
  <Page name="Industries">
    <v-row>
      <Banner text="ControlSoft Capabilities"/>
    </v-row>
    <v-row justify="center" align="center">
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <CapabilitiesDemo />
      </v-col>
    </v-row>
    <v-row>
      <Banner text="Industries Served"/>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <IndustriesServed/>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from '../templates/Page/Page';
import CapabilitiesDemo from '@/Pages/services_content/capabilities/CapabilitiesDemo';
import Banner from '@/components/Banner';
import IndustriesServed from '@/Pages/services_content/IndustriesServed';

export default {
  name: 'Services',
  metaInfo: {
    title: 'Services',
    meta: [
      {
        name: 'description',
        description: 'An overview of services ControlSoft offers',
      },
      {
        name: 'keyword',
        content: 'Control Automation, Electrical, Software Engineering, Simulation',
      },
      {
        name: 'description',
        description: 'ControlSoft offers automation services built upon decades of experience across a broad range of industries',
      },
      {
        name: 'keyword',
        content: 'Automation, Rockwell, Siemens, Kuka, Industrial Automation, Pharamaceutical automation, Food Automation, Construction Automation, Marine Automation, Mining Automation, Data Centers Automation, Life Sciences Automation',
      },
      {
        name: 'description',
        description: 'ControlSoft offers Simulation services that can visualise your product line before any work begins',
      },
      {
        name: 'keyword',
        content: 'Simulation, Visualisation, 3D, Visual Components, Factory Simulation',
      },
      {
        name: 'description',
        description: 'ControlSoft offers software development capabilities to solve any your problems from data analytics & visualisation , process management, or integration with your exisiting systems. ',
      },
      {
        name: 'keyword',
        content: 'Software development, Software Engineering, dev, development, cloud services, cloud,',
      },
      {
        name: 'description',
        description: 'ControlSoft offers Electrical and Panel building services from design to completeion',
      },
      {
        name: 'keyword',
        content: 'Electrical, Panel, Panel Building, Panel Design, Electrical schematics',
      },
    ],
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {
    Banner,
    IndustriesServed,
    CapabilitiesDemo,
    Page,
  },
};
</script>

<style scoped>

</style>
