import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import meta from './plugins/vueMeta';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
  meta,
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
