<template>
  <IndustryContent banner-text="Construction Materials - Client case study"
                   text="Construction"
                   icon="account-hard-hat"
                   icon-color="orange">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <h2>Client</h2>
    <p>
      The client has made significant contributions to the Irish Construction industry. They supply the widest and most
      diverse range of products. All products are produced to the highest international standards and backed by industry
      leading levels of technical support and customer service.
    </p>
    <h2>Project Summary</h2>
    <p>
      The client removed an old bevel line from their English factory which needed refurbishment and CE Marked 2017.
      This involved upgrading the old software, hardware and safety circuit. When the upgrade was completed the running
      of the line needed a full Software and Hardware commissioning and safety risk assessment, indicating the
      principles of redundant and diverse safeguarding measures. Along side Performance Level Required (PLr) according
      to EN 13849-1 which should meet a minimum of PL d and Safety Category 3 requirement.
    </p>
    <h2>Solution</h2>
    <p>
      We reprogrammed and tested the old PLC code to suit the new line layout (the line in England was bigger and had
      different start/end positions), updated the PLC, safety circuit and Drives hardware, ran risk assessment tests for
      the new safety system and documented according to EN 13849-1.
    </p>
    <ul>
      <li>PLC = 1756-L81E ControlLogix 5580 Controller.</li>
      <li>Servo Drives = 2198-S086-ERS3 Kinetix 5700</li>
      <li>PowerFlex Drive = PowerFlex 525-EENET</li>
      <li>Safety PLC = 1769-L33ERMS Compact GuardLogix 5370 Safety Controller.</li>
      <li>Safety PLC IO = 1734-IB8S Input Card, 1734-OB8S Output Card.</li>
      <li>Safety Gates = Euchner MGB-L1HB-EIA-R</li>
      <li>Safety Light Curtains = Allen Bradley 445L GuardShield robust light guards</li>
      <li>Safety Proximity Sensors = Allen Bradley 440n SensaGuard Proximity Switches</li>
    </ul>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';

export default {
  name: 'ConstructionServed',
  components: {IndustryContent},
  data() {
    return {
      img: require('@/assets/industries_served/construction.jpg'),
    };
  },
};
</script>

<style scoped>

</style>
