<template>
  <v-container>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <blockquote class="blockquote text-center larger">
          During our over-decade long tenure, we have developed cross discipline capabilities that
          enable us to integrate systems from electrical, mechanical, pneumatic, robotic, computer and IT
          infrastructure, networking hardware, configuration and database storage with reporting. ControlSoft is unique
          in that we have <b>mastered integrating these fields together</b> to solve any problem.
        </blockquote>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pa-0">
        <h2 class="text-center">Controlsoft - visualised </h2>
        <h3 class="text-center primary--text">select a category</h3>
        <div id="venn" class="venn pt-6"/>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" no-gutters>
      <v-col>
        <transition name="component-fade">
          <AutomationCapability v-if="text === 'Automation'" class="Automation"/>
          <ElectricalCapability v-else-if="text === 'Electrical'" class="Electrical"/>
          <SoftwareDevelopmentCapability v-else-if="text === 'Software'" class="Software"/>
          <SimulationCapability v-else-if="text === 'Simulation'" class="Simulation"/>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {select, selectAll} from 'd3';
import {VennDiagram} from '@upsetjs/venn.js';
import AutomationCapability from '@/Pages/services_content/capabilities/AutomationCapability';
import SoftwareDevelopmentCapability from '@/Pages/services_content/capabilities/SoftwareDevelopmentCapability';
import SimulationCapability from '@/Pages/services_content/capabilities/SimulationCapability';
import ElectricalCapability from '@/Pages/services_content/capabilities/ElectricalCapability';

export default {
  name: 'CapabilitiesDemo',
  components: {SoftwareDevelopmentCapability, AutomationCapability, SimulationCapability, ElectricalCapability},
  data() {
    return {
      text: 'Automation',
    };
  },
  methods: {
    buildChart() {
      const centreJoin = 2;
      const largest = 18;
      const join = 1;
      const small = 8;

      const sets = [
        {sets: ['Simulation'], size: largest},
        {sets: ['Electrical'], size: largest},
        {sets: ['Software'], size: largest},
        {sets: ['Automation'], size: largest},
        {sets: ['ControlSoft'], size: small},
        {sets: ['ControlSoft', 'Software'], size: centreJoin},
        {sets: ['ControlSoft', 'Simulation'], size: centreJoin},
        {sets: ['ControlSoft', 'Automation'], size: centreJoin},
        {sets: ['ControlSoft', 'Electrical'], size: centreJoin},
        {sets: ['Automation', 'Software'], size: join},
        {sets: ['Automation', 'Simulation'], size: join},
        {sets: ['Simulation', 'Electrical'], size: join},
        {sets: ['Software', 'Electrical'], size: join},
      ];

      const chart = VennDiagram();
      select('#venn')
          .datum(sets)
          .call(chart);
    },

    styleCircles() {
      const selectedColour = '#6EC6D8';
      const unselectedColour = '#65696B';
      const vennClasses = ['automation', 'controlsoft', 'simulation', 'software', 'electrical'];

      // set Automation as selected style, everything else as unselected style
      selectAll('.venn-circle path')
          .style('cursor', 'pointer')
          .style('fill-opacity', 0.8)
          .style('fill', (d) => {
            if (d.sets.includes('Automation')) {
              return selectedColour;
            }
            return unselectedColour;
          })
          .style('stroke', (d) => {
            if (d.sets.includes('Automation')) {
              return selectedColour;
            }
            return unselectedColour;
          });
      selectAll('.venn-circle path')
          .each(function(d, i) {
            this.classList.add(vennClasses[i]);
          });

      // style text
      selectAll('text')
          .style('fill', 'white')
          .style('font-weight', '100')
          .style('font-size', '20px');

      const target = this;

      // add click function
      selectAll('#venn .venn-circle')
          .filter((d) => {
            return !d.sets.includes('ControlSoft');
          })
          .on('click', function() {
            const circles = selectAll('#venn .venn-circle path')
                .filter((d) => {
                  return !d.sets.includes('ControlSoft');
                });
            circles.interrupt();
            pulse(circles);
            circles
                .style('fill', unselectedColour)
                .style('stroke', unselectedColour)
                .classed('clickable', false);
            const node = select(this).transition();
            target.text = node.select('text')._groups[0][0].textContent;
            node.select('path')
                .style('fill', selectedColour)
                .style('stroke', selectedColour);
            if (window.scrollY < 250) {
              window.scrollBy( 0, 250);
            }
          });

      // style ControlSoft circle
      const controlCircle = selectAll('.venn-circle path')
          .filter((d) => {
            return d.sets.includes('ControlSoft');
          });

      controlCircle.style('fill', selectedColour);
      controlCircle.style('fill-opacity', 1);
      controlCircle.style('stroke', selectedColour);

      // venn diagram box
      select('#venn')
          .select('svg')
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('viewBox', `100 -25 400 400`);

      // add pulse effect to all circles (except ControlSoft)
      const circle =
          selectAll('#venn .venn-circle')
              .filter((d) => {
                return !d.sets.includes('ControlSoft');
              });
      pulse(circle);

      /**
       * Animates the venn diagram
       * found here: https://observablehq.com/@bumbeishvili/pulse
       * @param {any} circle - d3 venn diagram selection
       */
      function pulse(circle) {
        (function repeat() {
          const transitionDuration = 500;
          circle
              .transition()
              .duration(transitionDuration)
              .attr('stroke-width', 0)
              .attr('stroke-opacity', 0)
              .transition()
              .duration(transitionDuration)
              .attr('stroke-width', 0)
              .attr('stroke-opacity', 0.5)
              .transition()
              .duration(transitionDuration * 2)
              .attr('stroke-width', 65)
              .attr('stroke-opacity', 0)
              .on('end', repeat);
        })();
      }
    },
  },
  mounted() {
    this.buildChart();
    this.styleCircles();
  },
};
</script>

<style scoped>
.larger {
  font-size: 20px;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active for <2.1.8 */
{
  opacity: 0;
}

text.label {
  cursor: pointer !important;
}
</style>
