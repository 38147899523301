<template>
  <IndustryContent text="Marine"
                   icon="ferry"
                   banner-text="Marine - XOCEAN Case Study"
                   icon-color="teal">
    <v-layout align-center justify-center>
      <v-img :src="img" class="parallax"></v-img>
    </v-layout>
    <h2>Client</h2>
    <p>
      XOCEAN offers turnkey oceanic survey solutions to clients.
      Clients can vary from commercial companies to universities and research institutions.
      Using their own Unmanned Surface Vessel’s (USV), they can collect and deliver data in one solution.
    </p>
    <h2>Project Summary</h2>
    <p>
      Develop a solution that will allow operators to control their assigned fleet of USV’s from anywhere in the world.
      The solution needs to be easily accessible, reliable, allow for various controls and display pertinent information
      in real-time relating to the status of the USV and any other vessels that may be in the vicinity of a USV.
    </p>
    <h2>Solution</h2>
    <p>
      The requirements were indicative of a Single Page Application (SPA) project – Various controls that can be loaded
      in and out of the page without reloading the app, display real-time information about the USV and offering a web
      portal that can be accessed anywhere on the globe without the need to preinstall any prerequisite components.
    </p>
    <p>
      ControlSoft decided to utilize Blazor, a leading-edge web framework developed by Microsoft to support development of our SPA project.
      Through Blazor, we gained the benefits of a responsive, extensible and versatile web application that supported integration with
      ControlSoft’s powerful and reliable controls interfaces and data services.
      Blazor also afforded the use of existing NuGet packages available within the .NET stack, reducing the time-to-market
      by enabling use of off-the-shelve components for many of the non-functional requirements.
    </p>
    <p>
      The Solution can be broken up into several parts:
      <ul>
        <li>User-Interface – A cloud hosted .NET Blazor front end providing operator control and real-time feedback.</li>
        <li>Cloud Services – A .NET Core Worker Service hosts in the cloud that addresses all of the unique business concerns.</li>
        <li>Integration Relays – A series of light weight components hosted on the USV, facilitating integration with the target USVs wide range of instruments.</li>
    </ul>
    </p>
    <p>
      The solution is hosted by XOCEAN within its own cloud infrastructure,
      providing robust securing and unparalleled scalability when compared to traditional operator control solutions.
    </p>
    <MarineServedTechnology/>
    <h2>Results</h2>
    <p>
      ControlSoft delivered a new Blazor SPA application that gives XOCEAN the ability to control their USV’s,
      get live information on their USV’s, and display information about any vessel nearby.
    </p>
    <p>
      ControlSoft delivered the revolutionary solution within a relatively short time-to-market,
      only three months following the commencement of development, providing XOCEAN with a rich and reliable operator control interface,
      significantly enhancing their ability to grow their business.
      XOCEAN utilizes this solution on every new USV added to their fleet, with the rollout to legacy vessels ongoing.
    </p>
  </IndustryContent>
</template>

<script>
import IndustryContent from '@/Pages/services_content/industries_served/IndustryContent';
import MarineServedTechnology from '@/Pages/services_content/industries_served/MarineServedTechnology';

export default {
  name: 'MarineServed',
  components: {IndustryContent, MarineServedTechnology},
  data() {
    return {
      img: require('@/assets/industries_served/XoceanUSV.png'),
    };
  },
};
</script>


<style scoped>

</style>
