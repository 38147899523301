<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-center">{{simulationHeading}}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout justify-center>
          <v-card elevation="0">
            <v-card-title class="align-center justify-center">
              <v-img :src="visualComponents" contain max-width="350px" class="visual-components" />
            </v-card-title>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="tab" center-active background-color="transparent" centered show-arrows color="primary" grow>
          <v-tab v-for="item in items" :key="item.name">
            <v-icon left large color="accent" class="video-icon">
              mdi-video-box
            </v-icon>
              {{item.name}}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.name">
        <v-row>
          <v-col >
            <iframe width="100%" height="500" allow="accelerometer; autoplay; encrypted-media; picture-in-picture" frameborder="0" :src="item.link">
            </iframe>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="0">
              <v-card-text class="text-center">
                {{item.description}}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  name: 'SimulationCapability',
  metaInfo: {
    title: 'Simulation Capabilities',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        description: 'ControlSoft offers Simulation services that can visualise your product line before any work begins',
      },
      {
        vmid: 'keyword',
        name: 'keyword',
        content: 'Simulation, Visualisation, 3D, Visual Components, Factory Simulation',
      },
    ],
    htmlAtts: {
      lang: 'en',
    },
  },
  data() {
    return {
      items: [
        {
          name: 'Pouch Stacking',
          link: 'https://www.youtube.com/embed/1CbQfH5wfVc',
          description: 'Pick and Place concept utilising a KUKA KR 3 robot. The solution stacks pouches of animal food in alternate directions on the feed before being sent into a flow wrapper.',
        },
        {
          name: 'Maneuvering Components',
          link: 'https://www.youtube.com/embed/5WLmqn1eRuY',
          description: 'The concept demos an automated welding cell on a washing machine assembly line. Components are maneuvered using the two Fanuc 710iC/50.',
        },
        {
          name: 'Palletization',
          link: 'https://www.youtube.com/embed/sZi9fj1Tby4',
          description: 'A pick a place concept for palletizing, utilizing a KR 120 R3200 PA. Products are lifted from a feed and placed on pallets for shipping.',
        },
        {
          name: 'Linear Transport',
          link: 'https://www.youtube.com/embed/qDglMpEhTag',
          description: 'Syringes are carried in with an iTRAK small frame conveyor. Boxes are assembled with a folding robot tool, then fed down the line so the syringes can be placed in them. Finally, the wipes and datasheets are placed on top.',
        },
        {
          name: 'Simulation in Location',
          link: 'https://www.youtube.com/embed/Y3X6Jt_TvAc',
          description: 'Syringe packaging with simulated location. Syringes are carried in with an iTRAK small frame conveyor. Boxes are assembled with a folding robot tool, then fed down the line so the syringes can be placed in them. Finally, the wipes and datasheets are placed on top.',
        },
      ],
      simulationHeading: 'Factory Simualtion from indivdual robots, to full product lines',
      simulationOverview: 'With Visual Components ControlSoft can simualte production Lines giving you the ability to see your finished product before phyisical work starts. ',
      tab: null,
      visualComponents: require('@/assets/partners/VisualComponentsLogo.png'),
    };
  },
};
</script>

<style scoped>
.video-icon {
    padding-right: 25px;
}
.visual-components {
    padding-bottom: 10px;
    padding-top: 10px;
}
</style>
